import { PaginationState } from "@tanstack/table-core";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import QuickFilters from "../components/common/QuickFilters/QuickFilters";
import SearchInput from "../components/common/Search/SearchInput";
import TableHeader from "../components/common/TableHeader/TableHeader";
import PersonTable from "../components/main/person/PersonTable";
import { ProgressProvider } from "../Context/ProgressContext";
import useFormNavigation from "../Context/UseFormNavigationContext";
import useTableHook from "../Context/UseTableContext";
import { SortState } from "../types/global";
import { Content, PeopleT } from "../types/People";
import ErrorBoundary from "../components/common/ErrorBoundary";

const Person = () => {
  const [openCreate, setOpenCreate] = useState(false);

  const { t } = useTranslation();

  const useTable = useTableHook();
  const formNavigation = useFormNavigation();

  const [data, setData] = useState<PeopleT | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [{ sortName, sortValue }, setSort] = useState<SortState>({
    sortName: "",
    sortValue: "",
  });

  const [selectedQuickFilter, setSelectedQuickFilter] = useState("ALL");

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const [tagsFilters, setTagsFilters] = useState<string[]>([]);
  const [tagsTypeFilter, setTagsTypeFilter] = useState<"AND" | "OR" | "NONE">(
    "AND"
  );

  const [companyFilters, setCompanyFilters] = useState<string>("");

  const defaultData = useMemo(() => [], []);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const sort = useMemo(
    () => ({
      sortName,
      sortValue,
    }),
    [sortName, sortValue]
  );

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/people`, {
        params: {
          page: pageIndex,
          size: pageSize,
          sort: `${sortName},${sortValue}`,
          search: searchParam ?? "",
          quickFilter: selectedQuickFilter ?? "",
          tags: tagsFilters.join(),
          companyIds: companyFilters ?? "",
          filtering: tagsTypeFilter,
        },
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [
    pageIndex,
    pageSize,
    sortName,
    sortValue,
    openCreate,
    searchParam,
    useTable?.refresh,
    selectedQuickFilter,
    tagsFilters,
    companyFilters,
    tagsTypeFilter,
  ]);

  useEffect(() => {
    formNavigation?.setTableData(data?.content as Content[]);
  }, [data, formNavigation]);

  return (
    <div className="flex flex-col py-12 xl:max-w-[1200px] 2xl:max-w-[1640px] w-[100vw] min-h-[816px] my-8 bg-white shadow-theme rounded-lg border-t-8 border-theme-person gap-7">
      <h1 className="text-theme-person font-bold 2xl:text-4xl xl:text-2xl mb-6 px-12">
        {t("person_title")}
      </h1>
      <div className="flex flex-row justify-between w-full gap-10 px-12">
        <ErrorBoundary>
          <QuickFilters
            theme={{
              bgColor: "bg-theme-person",
              borderColor: "border-theme-person",
              textColor: "text-theme-person",
              bgHover: "hover:bg-theme-person",
            }}
            setSelectedQuickFilter={setSelectedQuickFilter}
            selectedQuickFilter={selectedQuickFilter}
            formType={"people"}
            search={searchParam}
            tags={tagsFilters.join()}
            filtering={tagsTypeFilter}
            companyIds={companyFilters ?? ""}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <SearchInput
            id="searchParamPerson"
            name="searchParamPerson"
            placeholder={t("table_search")}
            setSearchParam={setSearchParam}
          />
        </ErrorBoundary>
      </div>
      <div className="w-full border-t-[1px] border-divisor pt-4">
        <ErrorBoundary>
          <TableHeader
            title={t("table_title_person")}
            formType="person"
            openCreate={openCreate}
            setOpenCreate={setOpenCreate}
            search={searchParam}
            tagsFilter={tagsFilters}
            companyIdFilter={companyFilters}
            quickFilter={selectedQuickFilter}
            setCompanyFilters={setCompanyFilters}
            setTagsFilters={setTagsFilters}
            setTagsTypeFilter={setTagsTypeFilter}
            tagsTypeFilter={tagsTypeFilter}
          />
        </ErrorBoundary>
        <ProgressProvider>
          <PersonTable
            data={data}
            defaultData={defaultData}
            isLoading={isLoading}
            pagination={pagination}
            setPagination={setPagination}
            setSort={setSort}
            sort={sort}
          />
        </ProgressProvider>
      </div>
    </div>
  );
};

export default Person;
