import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Spinner from "../../common/Spinner/Spinner";
import { t } from "i18next";
import MainTable from "../../common/MainTable/MainTable";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Dialog } from "../../common/Dialog/Dialog";
import { ContactMembershipsT } from "../../../types/ContactMemberships";
import { Item } from "../../common/FormComponents/SelectForm/SelectForm";
import DeleteDialog from "../../common/AlertDialog/Dialogs/DeleteDialog";
import { TrashIcon } from "@radix-ui/react-icons";
import FormButton from "../../common/FormComponents/FormButton";
import { AlertDialog } from "../../common/AlertDialog/AlertDialog";
import EmptyResults from "../../common/EmptyState/EmptyResults";
import ErrorBoundary from "../../common/ErrorBoundary";

interface RowEditDialogProps {
  row: ContactMembershipsT | any;
  value: string;
}
interface RowActionsProps {
  id: string;
  name: string;
  formType: "companies" | "people" | "contactLists";
  setMembershipsToDelete: Dispatch<SetStateAction<string[] | undefined>>;
}

const columnHelper = createColumnHelper<ContactMembershipsT>();

const RowActions = ({
  id,
  name,
  formType,
  setMembershipsToDelete,
}: RowActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-row justify-end gap-2">
      <AlertDialog
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        button={
          <FormButton
            customClass="h-6 w-6"
            theme={{
              borderColor: "border-red-600",
              bgColor: "",
              textColor: "",
            }}
          >
            <TrashIcon className="w-3 h-3 text-red-600" />
          </FormButton>
        }
      >
        <DeleteDialog
          formType={formType}
          id={id}
          setIsOpen={setIsOpen}
          message={`${t("delete_dialog_person_1")} ${name} ${t(
            "delete_dialog_person_2"
          )}`}
          setMembershipsToDelete={setMembershipsToDelete}
        />
      </AlertDialog>
    </div>
  );
};

const RowEditDialog = ({ row, value }: RowEditDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Item | null>(null);
  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      button={<span className="cursor-pointer">{value}</span>}
      formType={row?.personals ? "person" : "company"}
      edit={row}
      selectedPerson={row?.personals ? row?.id : null}
      setSelectedCompany={setSelectedCompany}
    />
  );
};

interface Props {
  data: ContactMembershipsT[] | null;
  isLoading: boolean;
  defaultData: never[];
  setMembershipsToDelete: Dispatch<SetStateAction<string[] | undefined>>;
}

const TableMemberships = ({
  data,
  isLoading,
  defaultData,
  setMembershipsToDelete,
}: Props) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) =>
          row.personals
            ? `${row.personals?.lastName} ${row.personals?.firstName}`
            : row.name,
        {
          id: "name",
          cell: (props) => (
            <RowEditDialog
              row={props.cell.row.original}
              value={
                props.cell.row.original?.personals
                  ? `${props.cell.row.original?.personals?.lastName}, ${props.cell.row.original?.personals?.firstName}`
                  : props.cell.row.original?.name
              }
            />
          ),
          header: () => <span>Name</span>,
        }
      ),
      columnHelper.accessor(
        (row) => (row?.personals ? row.employment.company?.name : row?.name),
        {
          id: "medium",
          cell: (props) => (
            <RowEditDialog
              row={props.cell.row?.original}
              value={
                props.cell.row.original?.personals
                  ? props.cell.row.original.employment.company?.name
                  : props.cell.row.original?.name
              }
            />
          ),
          header: () => <span>Medium</span>,
        }
      ),
      columnHelper.accessor((row) => row?.id, {
        id: "art",
        cell: (props) => (
          <RowEditDialog
            row={props.cell.row?.original}
            value={props.cell.row.original?.personals ? "Person" : "Medium"}
          />
        ),
        header: () => <span>Art</span>,
      }),
      columnHelper.accessor((row) => row?.mainEmail, {
        id: "mainEmail",
        header: () => <span>{t("table_person_header_email")}</span>,
        cell: (props) => (
          <RowEditDialog
            row={props.cell.row?.original}
            value={props.cell.getValue()}
          />
        ),
      }),
      columnHelper.display({
        id: "actions",
        header: () => (
          <span className="text-right">
            {t("table_person_header_quickaction")}
          </span>
        ),
        cell: (props) => (
          <RowActions
            id={props.cell.row.original?.id}
            name={
              props.cell.row.original?.personals
                ? `${props.cell.row.original.personals?.lastName}, ${props.cell.row.original?.personals?.firstName}`
                : props.cell.row.original?.name
            }
            formType={
              props.cell.row.original?.personals ? "people" : "companies"
            }
            setMembershipsToDelete={setMembershipsToDelete}
          />
        ),
      }),
    ],
    [setMembershipsToDelete]
  );

  const table = useReactTable({
    data: data ?? defaultData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  if (!data?.length && isLoading) {
    return (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-theme-distributor" size="h-20 w-20" />
      </div>
    );
  }

  if (!data?.length && !isLoading) {
    return <EmptyResults />;
  }

  return (
    <div className="relative py-2 w-full flex justify-between flex-col">
      <ErrorBoundary>
        <MainTable table={table} />
      </ErrorBoundary>
      {isLoading ? (
        <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-10 top-0 left-0">
          <Spinner customClass="fill-theme-company" size="h-20 w-20" />
        </div>
      ) : null}
    </div>
  );
};

export default TableMemberships;
