import {
  EnvelopeOpenIcon,
  FileTextIcon,
  InstagramLogoIcon,
  Link2Icon,
  MobileIcon,
  TwitterLogoIcon,
} from "@radix-ui/react-icons";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { ContactMethodsT } from "../../../../types/global";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  contact: ContactMethodsT;
}

type ContactIconsMap = {
  [key in ContactMethodsT["type"]]: JSX.Element;
};

type ContactTypeMap = {
  [key: string]: string;
};

const contactIcons: ContactIconsMap = {
  UNKNOWN: <></>,
  PHONE: <MobileIcon width={20} height={20} className="text-gray-400" />,
  EMAIL: <EnvelopeOpenIcon width={20} height={20} className="text-gray-400" />,
  TWITTER: <TwitterLogoIcon width={20} height={20} className="text-gray-400" />,
  FACEBOOK: <Link2Icon width={20} height={20} className="text-gray-400" />,
  INSTAGRAM: (
    <InstagramLogoIcon width={20} height={20} className="text-gray-400" />
  ),
  PRIVATE_PHONE: (
    <MobileIcon width={20} height={20} className="text-gray-400" />
  ),
  MOBILE: <MobileIcon width={20} height={20} className="text-gray-400" />,
  FAX: <FileTextIcon width={20} height={20} className="text-gray-400" />,
};

const contactTypeMap: ContactTypeMap = {
  PHONE: "tel",
  PRIVATE_PHONE: "tel",
  MOBILE: "tel",
  EMAIL: "mailto",
  FAX: "tel",
};

const ContactHref = ({ contact }: Props) => {
  const [icon, setIcon] = useState<JSX.Element | null>(null);
  const [typeOfHref, setTypeOfHref] = useState<string>("");

  useEffect(() => {
    const contactIcon = contactIcons[contact?.type];

    if (contactIcon) {
      setIcon(contactIcon);
      setTypeOfHref(contactTypeMap[contact?.type] || "");
    }
  }, [contact]);

  return (
    <a
      href={typeOfHref ? `${typeOfHref}:${contact.value}` : contact.value}
      target="_blank"
      rel="noreferrer"
    >
      {icon}
    </a>
  );
};

export default ContactHref;
