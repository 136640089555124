import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import useTableHook from "../../../../Context/UseTableContext";
import ChangeUserState from "../../../../lib/ChangeUserState";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
  changeType: "reactive" | "deactive";
  message: string;
}
const ChangeUserStateDialog = ({
  setIsOpen,
  id,
  message,
  changeType,
}: Props) => {
  const useTable = useTableHook();
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-8 px-6 py-4">
      <div className="flex flex-col items-center justify-start mt-4">
        <ExclamationTriangleIcon className="w-16 h-16" />
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="font-bold">{t("delete_dialog_header")}</h1>
        <p>{message}</p>
        <div className="flex flex-row gap-4">
          <button
            className="border-[1px] border-black bg-white rounded-full text-black font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300"
            onClick={() => setIsOpen(false)}
          >
            {t("users_dialog_cancel_button")}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              ChangeUserState(id, changeType);
              setTimeout(() => useTable?.refreshTable(), 1000);
              setIsOpen(false);
            }}
            className={`border-[1px] ${
              changeType === "deactive"
                ? "border-[#FF2B4B]"
                : "border-[#00D975]"
            } ${
              changeType === "deactive" ? "bg-[#FF2B4B]" : "bg-[#00D975]"
            } rounded-full text-white font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300`}
          >
            {t("users_dialog_save_button")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeUserStateDialog;
