import { useEffect, useState } from "react";
import { Dialog } from "../../common/Dialog/Dialog";
import { Content as ContentCompaniesT } from "../../../types/Companies";
import { Content as ContentContactListT } from "../../../types/ContactLists";
import { Content as ContentPeopleT } from "../../../types/People";
import { DropdownHome } from "./DropdownHome";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import useFormNavigation from "../../../Context/UseFormNavigationContext";
import ErrorBoundary from "../../common/ErrorBoundary";

interface Props {
  title: string;
  subtitle?: string;
  id: string;
  formType: "company" | "person" | "distributor";
  data: ContentPeopleT[] | ContentContactListT[] | ContentCompaniesT[] | any;
}

const LatestCard = ({ title, subtitle, id, formType, data }: Props) => {
  const formNavigation = useFormNavigation();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [editInfo, setEditInfo] = useState(() =>
    data.find(
      (el: ContentPeopleT | ContentContactListT | ContentCompaniesT | any) =>
        el.id === id
    )
  );

  useEffect(() => {
    formNavigation?.setTableData(data);
    data.length &&
      setEditInfo(
        data.find(
          (
            el: ContentPeopleT | ContentContactListT | ContentCompaniesT | any
          ) => el.id === id
        )
      );
  }, [data, formNavigation, id]);

  return (
    <div
      className={`flex flex-row justify-between items-center py-2 border-b-[1px] border-theme-distributor`}
    >
      <div className="flex flex-col w-full">
        <Dialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          button={
            <div className="cursor-pointer w-full">
              <p className="2xl:text-base xl:text-xs text-black font-bold truncate xl:max-w-[180px] 2xl:max-w-[323px]">
                {title}
              </p>
              {subtitle && (
                <p className="2xl:text-base xl:text-xs text-black truncate xl:max-w-[180px] 2xl:max-w-[323px]">
                  {subtitle}
                </p>
              )}
            </div>
          }
          formType={formType}
          edit={editInfo ?? null}
        />
      </div>
      <ErrorBoundary>
        <DropdownHome
          button={
            <button className="flex w-full gap-1 items-center px-2 bg-white xl:text-xs text-theme-distributor font-bol border-2 border-theme-distributor rounded-3xl ml-1">
              {t("home_card_button_dropdown")}
              <ChevronDownIcon />
            </button>
          }
          data={editInfo}
          formType={formType}
          setIsOpen={setIsOpen}
        />
      </ErrorBoundary>
    </div>
  );
};

export default LatestCard;
