import axios from "axios";

async function ChangeUserState(
  id: string,
  changeType: "reactive" | "deactive"
) {
  const headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  const reqOptions = {
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/users/${id}/status`,
    method: changeType === "deactive" ? "DELETE" : "POST",
    headers: headersList,
  };

  const responseData = axios
    .request(reqOptions)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });

  return responseData;
}

export default ChangeUserState;
