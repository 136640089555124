import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import docFile from "../../../../assets/doc-file.png";
import pdfFile from "../../../../assets/pdf-file.png";

interface Props {
  disabled: boolean;
  attachments: File[] | null;
  setAttachments: (attachments: File[] | null) => void;
}
const DropZone = ({ disabled, attachments, setAttachments }: Props) => {
  const { t } = useTranslation();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      ".pdf": [],
      ".docx": [],
    },
    maxFiles: 5,
    disabled: disabled,
    maxSize: 52 * 1000000, //50mb
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (acceptedFiles) {
      setAttachments(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <section className="w-full">
      <div
        {...getRootProps({
          className: `w-full border-[1px] border-theme-distributor rounded-xl min-h-[135px] p-2 h-full ${
            disabled ? "bg-gray-100" : "cursor-pointer"
          }`,
        })}
      >
        <input {...getInputProps()} />
        <div className="w-full h-full flex flex-col items-center justify-center">
          {attachments && attachments?.length > 0 ? (
            <>
              {attachments?.map((el) => (
                <div className="flex items-center gap-2 justify-start my-1">
                  <img
                    className="w-6"
                    src={el.name.includes("pdf") ? pdfFile : docFile}
                    alt="file-icon"
                  />
                  <span className="text-sm" key={el.size}>
                    {el.name} - {(el.size / 1024).toFixed(2)} KB
                  </span>
                </div>
              ))}
            </>
          ) : (
            <>
              <span className="text-theme-distributor text-xl">
                Drag & Drop
              </span>
              <span className="text-theme-distributor text-sm">oder</span>
              <button
                type="button"
                className="text-black text-sm underline font-bold"
                onClick={open}
              >
                {t("drag_and_drop_button")}
              </button>
            </>
          )}
        </div>
      </div>
      {/* <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside> */}
    </section>
  );
};

export default DropZone;
