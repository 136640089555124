import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { ChangeEvent, useState } from "react";

interface Props {
  name: string;
  id: string;
  placeholder: string;
  customClass?: string;
  setSearchParam: (searchParam: string) => void;
}

const SearchInput = ({
                       name,
                       id,
                       placeholder,
                       customClass,
                       setSearchParam,
                     }: Props) => {
  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      setSearchParam(value);
    }, 500) as unknown as number;

    setTimeoutId(newTimeoutId);
  };

  return (
      <form className="w-full">
        <div className="relative flex flex-row items-center text-theme-distributor">
          <MagnifyingGlassIcon className="absolute ml-2 w-6 h-6" />
          <input
              name={name}
              id={id}
              placeholder={placeholder}
              className={`w-full py-4 px-4 pl-10 border-[1px] border-theme-distributor rounded-lg  placeholder:text-center ${
                  customClass ?? ""
              }`}
              onChange={handleChange}
          />
        </div>
      </form>
  );
};

export default SearchInput;
