import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import * as SelectPrimitive from "@radix-ui/react-select";
import { clsx } from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useProgressHook from "../../../../Context/UseProgressContext";
import SelectFormButton from "./SelectFormButton";

export type Item = {
  id: string;
  name: string;
  type?: string;
};

type SelectProps = {
  name: string;
  items: Item[];
  value: Item | null;
  setValue(value: Item): void;
  doNotMark?: boolean;
  disabled?: boolean;
  label?: string | null;
  customClass?: string;
};

const SelectForm = ({
  name,
  label,
  items,
  customClass,
  value,
  setValue,
  doNotMark = false,
  disabled = false,
}: SelectProps) => {
  const [mark, setMark] = useState(true);
  const progressContext = useProgressHook();

  const { t } = useTranslation();

  const [idValue, setIdValue] = useState("");

  useEffect(() => {
    if (progressContext?.handleMark(name) === false) {
      setMark(false);
    } else {
      !doNotMark && setMark(true);
    }
  }, [doNotMark, name, progressContext]);

  useEffect(() => {
    if (idValue) {
      const newItem = items.find((item) => idValue === item.id);
      if (newItem) {
        setValue(newItem);
      }
      !doNotMark && setMark(false);
      !doNotMark && progressContext?.handleStates(name, false);
    } else {
      !doNotMark && setMark(true);
      !doNotMark && progressContext?.handleStates(name, true);
    }
  }, [idValue, name, doNotMark]);

  useEffect(() => {
    if (doNotMark) {
      setMark(false);
    }
  }, [doNotMark]);

  if (!items) {
    return <></>;
  }

  return (
    <div className="flex flex-col justify-start w-full ">
      {label && (
        <label className="text-base font-bold pb-2 flex flex-row items-start gap-1">
          {label}
          {mark && (
            <div className="w-2 h-2 border-2 border-yellow-400 rounded-full bg-yellow-400"></div>
          )}
        </label>
      )}
      <SelectPrimitive.Root
        value={idValue}
        onValueChange={setIdValue}
        name={idValue}
        disabled={disabled}
      >
        <SelectPrimitive.Trigger asChild>
          <SelectFormButton customClass={customClass}>
            <SelectPrimitive.Value className="text-left">
              {value?.name ?? (
                <span className="text-theme-distributor">
                  {t("form_person_data_select_placeholder")}
                </span>
              )}
            </SelectPrimitive.Value>
          </SelectFormButton>
        </SelectPrimitive.Trigger>

        <SelectPrimitive.Portal className="z-50">
          <SelectPrimitive.Content align="center">
            <SelectPrimitive.ScrollUpButton className="flex items-center justify-center text-gray-700">
              <ChevronUpIcon />
            </SelectPrimitive.ScrollUpButton>
            <SelectPrimitive.Viewport className="bg-white p-2 rounded-lg shadow-lg">
              <SelectPrimitive.Group>
                {items?.map((f, i) => (
                  <SelectPrimitive.Item
                    key={`${f.id}-${i}`}
                    value={f.id}
                    className={clsx(
                      "relative flex items-center px-8 py-2 rounded-md text-sm text-gray-700 font-medium focus:bg-gray-100",
                      "radix-disabled:opacity-50",
                      "focus:outline-none select-none"
                    )}
                  >
                    <SelectPrimitive.ItemText>
                      {f.name}
                    </SelectPrimitive.ItemText>
                    <SelectPrimitive.ItemIndicator /* className="absolute left-2 inline-flex items-center" */
                    >
                      <CheckIcon />
                    </SelectPrimitive.ItemIndicator>
                  </SelectPrimitive.Item>
                ))}
              </SelectPrimitive.Group>
            </SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className="flex items-center justify-center text-gray-700">
              <ChevronDownIcon />
            </SelectPrimitive.ScrollDownButton>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    </div>
  );
};

export { SelectForm };