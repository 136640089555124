import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="xl:max-w-[1200px] 2xl:max-w-[1640px] w-[100vw] border-r-gray-200 border-t-[1px] mb-10">
      <div className="flex justify-between px-4">
        <span className="text-theme-distributor font-bold text-sm pt-4">
          {t("footer_left")}
        </span>
        <div className="flex flex-row gap-4">
          <span className="text-theme-distributor font-bold text-sm pt-4">
            {t("footer_right")} -{" "}
            <Link
              to="https://napps.solutions/"
              target="_blank"
              className="text-theme-distributor font-bold text-sm pt-4 underline"
            >
              {t("footer_right_link")}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
