import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { Content } from "../types/Users";
import useTableHook from "./UseTableContext";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "../components/common/Spinner/Spinner";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

type UserContextProps = {
  user: Content | null;
};

export const UserContext = createContext<UserContextProps | null>(null);

export const UserProvider: React.FC<any> = ({ children }) => {
  const [user, setUser] = useState<Content | null>(null);
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const useTable = useTableHook();

  const {
    getAccessTokenSilently,
    loginWithRedirect,
    isAuthenticated,
    isLoading: isLoadingUser,
    logout,
  } = useAuth0();

  useEffect(() => {
    if (!isLoadingUser && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoadingUser, loginWithRedirect]);

  useEffect(() => {
    const fetchToken = async () => {
      const response = await getAccessTokenSilently();
      Cookies.set("access_token", response);
      const decodedToken: any = jwt_decode(response);
      const expirationTime = decodedToken?.exp * 1000;
      const currentTime = new Date().getTime();

      if (expirationTime < currentTime) {
        logout();
      }

      setToken(response);
    };

    fetchToken();
  }, [getAccessTokenSilently, logout]);

  useEffect(() => {
    const interceptor = axios.interceptors.request.use(
      async (request) => {
        if (token) {
          request.headers["Authorization"] = `Bearer ${token}`;
        }
        return request;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/v1/me`,
          {
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        setUser(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      setIsLoading(true);
      fetchData();
    }
  }, [token, useTable?.refresh]);

  if (isLoading || !token) {
    return (
      <div className="absolute w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-black" size="h-20 w-20" />
      </div>
    );
  }

  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
