import { PaginationState } from "@tanstack/table-core";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import SearchInput from "../components/common/Search/SearchInput";
import TableHeader from "../components/common/TableHeader/TableHeader";
import { SortState } from "../components/main/person/PersonTable";
import UsersTable from "../components/main/users/UsersTable";
import { ProgressProvider } from "../Context/ProgressContext";
import useTableHook from "../Context/UseTableContext";
import useUserHook from "../Context/UseUserContext";
import { UsersT } from "../types/Users";
import ErrorBoundary from "../components/common/ErrorBoundary";

const Users = () => {
  const [openCreate, setOpenCreate] = useState(false);

  const { t } = useTranslation();
  const useTable = useTableHook();
  const useUser = useUserHook();

  const [data, setData] = useState<UsersT | null>(null);

  const [searchParam, setSearchParam] = useState("");
  const [statusFilter, setStatusFilters] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [{ sortName, sortValue }, setSort] = useState<SortState>({
    sortName: "",
    sortValue: "",
  });

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const defaultData = useMemo(() => [], []);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const sort = useMemo(
    () => ({
      sortName,
      sortValue,
    }),
    [sortName, sortValue]
  );

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/users`, {
        params: {
          page: pageIndex,
          size: pageSize,
          sort: `${sortName},${sortValue}`,
          search: searchParam,
          states: statusFilter,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [
    pageIndex,
    pageSize,
    sortName,
    sortValue,
    searchParam,
    useTable?.refresh,
    statusFilter,
  ]);

  if (useUser?.user?.role !== "ADMIN" && useUser?.user?.role !== "OWNER") {
    Navigate({ to: "/" });
  }

  return (
    <div className="flex flex-col py-12 xl:max-w-[1200px] 2xl:max-w-[1640px] w-[100vw] min-h-[816px] my-8 bg-white shadow-theme rounded-lg border-t-8 border-black gap-7">
      <h1 className="text-black font-bold 2xl:text-4xl xl:text-2xl mb-6 px-12">
        {t("user_title")}
      </h1>
      <div className="flex flex-row justify-between w-full gap-10 px-12">
        <ErrorBoundary>
          <SearchInput
            id="searchParamUser"
            name="searchParamUser"
            placeholder={t("table_search")}
            setSearchParam={setSearchParam}
          />
        </ErrorBoundary>
      </div>
      <div className="w-full border-t-[1px] border-divisor pt-4">
        <ErrorBoundary>
          <TableHeader
            title={t("table_title_user")}
            formType="user"
            openCreate={openCreate}
            setOpenCreate={setOpenCreate}
            search={searchParam}
            setStatusFilter={setStatusFilters}
          />
        </ErrorBoundary>
        <ProgressProvider>
          <UsersTable
            data={data}
            defaultData={defaultData}
            isLoading={isLoading}
            pagination={pagination}
            setPagination={setPagination}
            setSort={setSort}
            sort={sort}
          />
        </ProgressProvider>
      </div>
    </div>
  );
};

export default Users;
