import { useTranslation } from "react-i18next";
import useUserHook from "../../../../Context/UseUserContext";

interface Props {
  handleResetForm: () => void;
  setIsOpen: (isOpen: boolean) => void;
}
const FormFooterCreate = ({ handleResetForm, setIsOpen }: Props) => {
  const { t } = useTranslation();

  const useUser = useUserHook();
  
  return (
    <div className="mt-4 flex flex-row justify-end gap-4">
      <button
        type="button"
        className="border-[1px] border-theme-distributor bg-white rounded-full text-base text-theme-distributor font-bold px-8 py-1"
        onClick={(e) => {
          e.preventDefault();
          handleResetForm();
          setIsOpen(false);
        }}
      >
        {t("form_buttons_discard")}
      </button>
      {useUser?.user?.role !== "READER"  && (
        <button className="border-[1px] border-[#00D975] bg-[#00D975] rounded-full text-base text-white font-bold px-8 py-1">
          {t("form_buttons_save")}
        </button>
      )}
    </div>
  );
};

export default FormFooterCreate;
