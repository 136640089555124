import searchImg from "../../../assets/search.png";

const EmptyResults = () => {
  return (
    <div className="w-full flex flex-row justify-center">
      <div className="flex flex-col gap-8 justify-center items-center">
        <div className="w-52 h-52 p-4 border-2 rounded-full bg-gray-200 flex items-center justify-center">
          <img src={searchImg} alt="no results found" className="w-36" />
        </div>

        <span className="text-2xl text-theme-distributor font-bold">No results found</span>
        <span className="text-xl text-black font-bold">
          Try adjusting your search or filter to find what you're looking for
        </span>
      </div>
    </div>
  );
};

export default EmptyResults;
