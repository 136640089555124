import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconCompany, IconDistributor, IconPerson } from "../assets/icons";
import SearchHome from "../components/main/home/SearchHome";
import Spinner from "../components/common/Spinner/Spinner";
import CardHome from "../components/main/home/CardHome";
import { FormNavigationProvider } from "../Context/FormNavigationContext";
import { ProgressProvider } from "../Context/ProgressContext";
import useTableHook from "../Context/UseTableContext";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorBoundary from "../components/common/ErrorBoundary";

const Home = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [people, setPeople] = useState([]);

  const tableContext = useTableHook();

  const { user } = useAuth0();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/people`, {
        params: {
          size: 3,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setPeople(response.data.content);
      })
      .catch((error) => console.log(error));

    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/companies`, {
        params: {
          size: 3,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setCompanies(response.data.content);
      })
      .catch((error) => console.log(error));

    setIsLoading(false);
  }, [tableContext?.refresh]);

  return (
    <>
      {isLoading && (
        <div className="absolute w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
          <Spinner customClass="fill-black" size="h-20 w-20" />
        </div>
      )}
      <div className="flex flex-col p-12 xl:max-w-[1200px] 2xl:max-w-[1640px] w-[100vw] min-h-[816px] my-8 bg-white shadow-theme rounded-lg border-t-8 border-black gap-7">
        <div>
          <h1 className="text-2xl font-bold">
            {t("home_hello")} {user?.email}
          </h1>
          <p>{t("home_hello_message")}</p>
        </div>
        {/* Currently removed by task #205 */

        /* <div>
          <ErrorBoundary>
            <SearchHome
              name="homeInput"
              id="homeInput"
              placeholder={t("home_search_placeholder")}
            />
          </ErrorBoundary>
        </div> */}
        <div className="flex flex-row justify-between items-start flex-wrap">
          <ProgressProvider>
            <FormNavigationProvider>
              <CardHome
                theme={{
                  bgColor: "bg-theme-person",
                  borderColor: "border-theme-person",
                  textColor: "text-theme-person",
                }}
                title={t("home_card_person")}
                icon={<IconPerson />}
                formType="person"
                data={people}
              />
            </FormNavigationProvider>
            <FormNavigationProvider>
              <CardHome
                theme={{
                  bgColor: "bg-theme-company",
                  borderColor: "border-theme-company",
                  textColor: "text-theme-company",
                }}
                title={t("home_card_company")}
                icon={<IconCompany />}
                formType="company"
                data={companies}
              />
            </FormNavigationProvider>
            <FormNavigationProvider>
              <CardHome
                theme={{
                  bgColor: "bg-theme-distributor",
                  borderColor: "border-theme-distributor",
                  textColor: "text-theme-distributor",
                }}
                title={t("home_card_distributor")}
                icon={<IconDistributor />}
                formType="distributor"
              />
            </FormNavigationProvider>
          </ProgressProvider>
        </div>
      </div>
    </>
  );
};

export default Home;
