import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  message: string;
}
const ErrorDialog = ({ setIsOpen, message }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-8 px-6 py-4">
      <div className="flex flex-col items-center justify-start mt-4">
        <ExclamationTriangleIcon className="w-16 h-16" />
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="font-bold">{t("delete_dialog_header")}</h1>
        <p>{message}</p>
        <div className="flex w-full gap-4 justify-end">
          <button
            onClick={() => {
              setIsOpen(false);
            }}
            className="border-[1px] border-black bg-white rounded-full text-black font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300"
          >
            {t("error_dialog_continue")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorDialog;
