import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { USER_STATUS } from "../../../../mocks/USER_STATUS";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as LabelPrimitive from "@radix-ui/react-label";

import clsx from "clsx";
import { CheckIcon } from "@radix-ui/react-icons";

interface Props {
  setStatusFilter?: Dispatch<SetStateAction<string>>;
}

interface Status {
  name: string;
  value: boolean;
}

const UserFilters = ({ setStatusFilter }: Props) => {
  const [selectedStatus, setSelectedStatus] = useState<Status[]>([
    { name: "INVITED", value: true },
    { name: "ACTIVE", value: true },
    { name: "INACTIVE", value: true },
  ]);

  useEffect(() => {
    if (selectedStatus && setStatusFilter) {
      const filter = selectedStatus
        .filter((el) => el.value)
        .map((elMap) => elMap.name)
        .join();
      setStatusFilter(filter);
    }
  }, [selectedStatus, setStatusFilter]);

  const handleStatusChange = useCallback((name: string, checked: boolean) => {
    setSelectedStatus((old) =>
      old.map((status) => {
        if (status.name === name) {
          return { ...status, value: checked };
        }
        return status;
      })
    );
  }, []);

  const selectedCount = selectedStatus.filter((status) => status.value).length;

  return (
    <div className="flex w-full gap-4">
      <div className="flex flex-col min-w-[200px] max-w-[200px] border-[1px] border-gray-300 p-4 rounded-xl mt-2">
        {USER_STATUS.map((el, index) => {
          const checked = selectedStatus.find(
            (status) => status.name === el
          )?.value;
          const disabled = selectedCount === 1 && checked;

          return (
            <div className="flex items-center p-1" key={index}>
              <CheckboxPrimitive.Root
                id={`c${index}`}
                checked={checked}
                disabled={disabled}
                className={clsx(
                  "flex h-5 w-5 items-center justify-center rounded",
                  "radix-state-checked:bg-black radix-state-unchecked:bg-gray-300",
                  "focus:outline-none focus-visible:ring focus-visible:ring-black focus-visible:ring-opacity-75"
                )}
                onCheckedChange={(isChecked: boolean) =>
                  handleStatusChange(el, isChecked)
                }
              >
                <CheckboxPrimitive.Indicator>
                  <CheckIcon className="h-4 w-4 self-center text-white" />
                </CheckboxPrimitive.Indicator>
              </CheckboxPrimitive.Root>
              <LabelPrimitive.Label
                htmlFor={`c${index}`}
                className="ml-3 select-none text-sm font-medium text-gray-900"
              >
                {el}
              </LabelPrimitive.Label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { UserFilters };
