import { t } from "i18next";

export const EXPORT_COMPANY_OTHERS = [
  { id: "tags", label: t("form_company_details_label_keywords") },
  { id: "remarks", label: t("form_company_details_label_remarks") },
];

export const EXPORT_COMPANY_DETAILS = [
  { id: "mainEmail", label: `Main ${t("form_company_details_label_email")}` },
  { id: "name", label: t("form_company_details_label_medium") },
  { id: "branch", label: t("form_company_details_label_branch") },
  { id: "category", label: t("form_company_details_label_category") },
  { id: "homepage", label: t("form_company_details_label_twitter") },
];

export const EXPORT_COMPANY_ADDRESS = [
  { id: "streetName", label: t("form_company_address_street") },
  { id: "place", label: t("form_company_address_place") },
  { id: "zipCode", label: t("form_company_address_zipcode") },
  { id: "country", label: t("form_company_address_country") },
];

export const EXPORT_COMPANY_CONTACTS = [
  {
    id: "contactMethods.EMAIL",
    label: "E-mail",
  },
  {
    id: "contactMethods.PHONE",
    label: "Phone",
  },
  {
    id: "contactMethods.TWITTER",
    label: "Twitter",
  },
  {
    id: "contactMethods.FACEBOOK",
    label: "Facebook",
  },
  {
    id: "contactMethods.INSTAGRAM",
    label: "Instagram",
  },
  {
    id: "contactMethods.PRIVATE_PHONE",
    label: t("contactType_private_phone"),
  },
  {
    id: "contactMethods.MOBILE",
    label: t("contactType_mobile"),
  },
  {
    id: "contactMethods.FAX",
    label: t("contactType_fax"),
  },
  {
    id: "contactMethods.UNKNOWN",
    label: "Unknown",
  },
];
