export const SUFFIXED = [
  { id: "1", name: "BA" },
  { id: "2", name: "BEng" },
  { id: "3", name: "BSc" },
  { id: "4", name: "MA" },
  { id: "5", name: "LLM" },
  { id: "6", name: "MSc" },
  { id: "7", name: "MAS" },
  { id: "8", name: "MA" },
  { id: "9", name: "MBA" },
  { id: "10", name: "MIB" },
  { id: "11", name: "MP" },
  { id: "12", name: "MSc" },
  { id: "13", name: "PhD" },
];
