import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Content as ContentCompaniesT } from "../types/Companies";
import { Content as ContentContactListT } from "../types/ContactLists";
import { Content as ContentPeopleT } from "../types/People";

interface FormNavigationContextProps {
  setTableData: Dispatch<
    SetStateAction<
      ContentPeopleT[] | ContentContactListT[] | ContentCompaniesT[] | null
    >
  >;
  setCurrentItem: Dispatch<
    SetStateAction<
      ContentPeopleT | ContentContactListT | ContentCompaniesT | null
    >
  >;
  prevItem: ContentPeopleT | ContentContactListT | ContentCompaniesT | any;
  nextItem: ContentPeopleT | ContentContactListT | ContentCompaniesT | any;
  currentItem: ContentPeopleT | ContentContactListT | ContentCompaniesT | any;
}

export const FormNavigationContext =
  createContext<FormNavigationContextProps | null>(null);

export const FormNavigationProvider: React.FC<any> = ({ children }) => {
  const [tableData, setTableData] = useState<
    ContentPeopleT[] | ContentContactListT[] | ContentCompaniesT[] | null
  >(null);
  const [currentItem, setCurrentItem] = useState<
    ContentPeopleT | ContentContactListT | ContentCompaniesT | null
  >(null);
  const [prevItem, setPrevItem] = useState<
    ContentPeopleT | ContentContactListT | ContentCompaniesT | null
  >(null);
  const [nextItem, setNextItem] = useState<
    ContentPeopleT | ContentContactListT | ContentCompaniesT | null
  >(null);

  useEffect(() => {
    if (currentItem && tableData) {
      const index = tableData?.findIndex(
        (
          item: ContentPeopleT | ContentContactListT | ContentCompaniesT | null
        ) => item?.id === currentItem?.id
      );

      const previousElement =
        (tableData && tableData[(index as number) - 1]) ?? null;
      const nextElement =
        (tableData && tableData[(index as number) + 1]) ?? null;

      setPrevItem(previousElement ?? null);
      setNextItem(nextElement ?? null);
    }
  }, [currentItem, tableData]);

  return (
    <FormNavigationContext.Provider
      value={{
        setTableData,
        setCurrentItem,
        prevItem,
        nextItem,
        currentItem,
      }}
    >
      {children}
    </FormNavigationContext.Provider>
  );
};
