import axios from "axios";

async function DeleteFunctions(
  id: string,
  tableName: "companies" | "people" | "contactLists" | "users" | "tags"
) {
  const bodyContent = JSON.stringify({
    id: id,
  });

  const headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  const reqOptions = {
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/${tableName}/${id}`,
    method: "DELETE",
    headers: headersList,
    data: bodyContent,
  };

  const responseData = axios
    .request(reqOptions)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });

  return responseData;
}

export default DeleteFunctions;
