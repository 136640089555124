import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import {
  ChevronDownIcon,
  MobileIcon,
  SymbolIcon,
  TrashIcon,
} from "@radix-ui/react-icons";

import Spinner from "../../common/Spinner/Spinner";
import { t } from "i18next";
import MainTable from "../../common/MainTable/MainTable";
import { Dispatch, SetStateAction, useState } from "react";
import { SortState } from "../../../types/global";
import { AlertDialog } from "../../common/AlertDialog/AlertDialog";
import FormButton from "../../common/FormComponents/FormButton";
import EmptyResults from "../../common/EmptyState/EmptyResults";
import { Content, UsersT } from "../../../types/Users";
import ChangeUserStateDialog from "../../common/AlertDialog/Dialogs/ChangeUserStateDialog";
import { Item } from "../../common/FormComponents/SelectForm/SelectForm";
import {
  ROLES,
  ROLES_OPTION_ADMIN,
  ROLES_OPTION_OWNER,
} from "../../../mocks/ROLES";
import useUserHook from "../../../Context/UseUserContext";

import Dropdown from "../../common/Dropdown/Dropdown";
import { AlertDialogRole } from "../../common/AlertDialog/AlertDialogRole";
import DeleteDialog from "../../common/AlertDialog/Dialogs/DeleteDialog";
import ErrorBoundary from "../../common/ErrorBoundary";

interface RowActionsProps {
  id: string;
  email: string;
  state: string;
  role: string;
}
interface RowRolesActionProps {
  id: string;
  role: string;
  email: string;
}

const columnHelper = createColumnHelper<Content>();

const RowActions = ({ id, email, state, role }: RowActionsProps) => {
  const [isOpenReactive, setIsOpenReactive] = useState(false);
  const [isOpenDeactive, setIsOpenDeactive] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const useUser = useUserHook();
  return (
    <div className="flex flex-row justify-end gap-2">
      {useUser?.user?.id !== id && role !== "OWNER" && (
        <>
          {state === "INACTIVE" ? (
            <AlertDialog
              setIsOpen={setIsOpenReactive}
              isOpen={isOpenReactive}
              button={
                <FormButton
                  customClass="h-6 w-6"
                  theme={{
                    borderColor: "border-[#00D975]",
                    bgColor: "",
                    textColor: "",
                  }}
                >
                  <SymbolIcon className="w-3 h-3 text-[#00D975]" />
                </FormButton>
              }
            >
              <ChangeUserStateDialog
                changeType="reactive"
                id={id}
                setIsOpen={setIsOpenReactive}
                message={`${t("users_reactive_dialog_message")} ${email}`}
              />
            </AlertDialog>
          ) : (
            <AlertDialog
              setIsOpen={setIsOpenDeactive}
              isOpen={isOpenDeactive}
              button={
                <FormButton
                  customClass="h-6 w-6"
                  theme={{
                    borderColor: "border-red-600",
                    bgColor: "",
                    textColor: "",
                  }}
                >
                  <SymbolIcon className="w-3 h-3 text-red-600" />
                </FormButton>
              }
            >
              <ChangeUserStateDialog
                changeType="deactive"
                id={id}
                setIsOpen={setIsOpenDeactive}
                message={`${t("users_deactive_dialog_message")} ${email}`}
              />
            </AlertDialog>
          )}

          <AlertDialog
            setIsOpen={setIsOpenDelete}
            isOpen={isOpenDelete}
            button={
              <FormButton
                customClass="h-6 w-6"
                theme={{
                  borderColor: "border-red-600",
                  bgColor: "",
                  textColor: "",
                }}
              >
                <TrashIcon className="w-3 h-3 text-red-600" />
              </FormButton>
            }
          >
            <DeleteDialog
              formType="users"
              id={id}
              setIsOpen={setIsOpenDelete}
              message={`${t("users_delete_dialog_message")} ${email}`}
            />
          </AlertDialog>
        </>
      )}
    </div>
  );
};

interface Props {
  data: UsersT | null;
  isLoading: boolean;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  defaultData: never[];
  setSort: Dispatch<SetStateAction<SortState>>;
  sort: {
    sortName: string;
    sortValue: string;
  };
}
const UsersTable = ({
  data,
  isLoading,
  setPagination,
  pagination,
  defaultData,
  setSort,
  sort,
}: Props) => {
  const RowRolesAction = ({ id, role, email }: RowRolesActionProps) => {
    const [selectedRole, setSelectedRole] = useState<Item | null>(
      () => ROLES.find((el) => el?.name === role) ?? null
    );

    const useUser = useUserHook();

    return (
      <div className="flex">
        {useUser?.user?.id === id ||
        (useUser?.user?.role !== "OWNER" && role === "ADMIN") ||
        role === "OWNER" ? (
          <span className="">{t(`users_role_${role}`)}</span>
        ) : (
          <ErrorBoundary>
            <Dropdown
              button={
                <button className="flex gap-1 items-center ">
                  {t(`users_role_${selectedRole?.name}`)}
                  <ChevronDownIcon />
                </button>
              }
              children={
                <>
                  {useUser?.user?.role === "ADMIN" ? (
                    <div className="flex flex-col gap-2">
                      {ROLES_OPTION_ADMIN.map((el) => (
                        <AlertDialogRole
                          email={email}
                          id={el.id}
                          name={el.name}
                          oldName={role}
                          button={
                            <button className="">
                              {t(`users_role_${el.name}`)}
                            </button>
                          }
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col gap-2">
                      {ROLES_OPTION_OWNER.map((el) => (
                        <AlertDialogRole
                          email={email}
                          id={id}
                          name={el.name}
                          oldName={role}
                          button={
                            <button className="">
                              {t(`users_role_${el.name}`)}
                            </button>
                          }
                        />
                      ))}
                    </div>
                  )}
                </>
              }
            />
          </ErrorBoundary>
        )}
      </div>
    );
  };

  const columns = [
    columnHelper.accessor((row) => row?.email, {
      id: "email",
      cell: (props) => props.cell.getValue(),
      header: () => <span>{t("table_users_header_email")}</span>,
    }),
    columnHelper.accessor((row) => row?.phone, {
      id: "phone",
      cell: (props) => (
        <a
          href={`tel:${props.cell.getValue()}`}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer flex gap-2 items-center"
        >
          {props.cell.getValue() && (
            <MobileIcon className="text-gray-400" width={18} height={18} />
          )}
          {props.cell.getValue()}
        </a>
      ),
      header: () => <span>{t("table_users_header_phone")}</span>,
    }),
    columnHelper.accessor((row) => row?.department, {
      id: "department",
      cell: (props) => props.cell.getValue(),
      header: () => <span>{t("table_users_header_department")}</span>,
    }),
    columnHelper.accessor((row) => row?.state, {
      id: "state",
      cell: (props) => props.cell.getValue(),
      header: () => <span>{t("table_users_header_state")}</span>,
    }),
    columnHelper.accessor((row) => row?.role, {
      id: "role",
      cell: (props) => (
        <RowRolesAction
          id={props.cell.row.original.id}
          role={props.cell.row.original?.role}
          email={props.cell.row.original?.email}
        />
      ),
      header: () => <span>{t("table_users_header_role")}</span>,
    }),
    columnHelper.accessor((row) => new Date(row?.created).toLocaleString(), {
      id: "created",
      cell: (props) => props.cell.getValue(),
      header: () => <span>{t("table_users_header_date")}</span>,
    }),
    columnHelper.display({
      id: "actions",
      header: () => (
        <span className="text-right">
          {t("table_distributor_header_quickaction")}
        </span>
      ),
      cell: (props) => (
        <RowActions
          id={props.cell.row.original.id}
          email={props.cell.row.original.email}
          state={props.cell.row.original?.state}
          role={props.cell.row.original?.role}
        />
      ),
    }),
  ];

  const table = useReactTable({
    data: data?.content ?? defaultData,
    columns,
    pageCount: data?.totalPages ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  if (!data && isLoading) {
    return (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-theme-distributor" size="h-20 w-20" />
      </div>
    );
  }

  if (!data?.content.length && !isLoading) {
    return <EmptyResults />;
  }

  return (
    <div className="relative py-2 px-12 w-full flex justify-between flex-col">
      <ErrorBoundary>
        <MainTable
          table={table}
          setSort={setSort}
          sort={sort}
          sizesPage={[50, 100, 200]}
        />
      </ErrorBoundary>
      {isLoading ? (
        <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-10 top-0 left-0">
          <Spinner customClass="fill-theme-distributor" size="h-20 w-20" />
        </div>
      ) : null}
    </div>
  );
};

export default UsersTable;
