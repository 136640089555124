import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FormInput from "../../common/FormComponents/FormInput";
import {
  Item,
  SelectForm,
} from "../../common/FormComponents/SelectForm/SelectForm";
import FormTextArea from "../../common/FormComponents/FormTextArea";
import FormFooterCreate from "../../common/FormComponents/FormFooter/FormFooterCreate";
import FormHeaderCreate from "../../common/FormComponents/FormHeader/FormHeaderCreate";
import axios from "axios";
import { Content as ContentContactLists } from "../../../types/ContactLists";
import { Content as ContentContacts } from "../../../types/Contacts";
import { ContactListsFormData, DistributorMarksT } from "../../../types/global";
import UpdateFunction from "../../../lib/UpdateFunction";
import FormHeaderNormal from "../../common/FormComponents/FormHeader/FormHeaderNormal";
import CreateFunction from "../../../lib/CreateFunction";
import useProgressHook from "../../../Context/UseProgressContext";
import useTableHook from "../../../Context/UseTableContext";
import useFormNavigation from "../../../Context/UseFormNavigationContext";
import FormFooterNormal from "../../common/FormComponents/FormFooter/FormFooterNormal";
import { ContactMembershipsT } from "../../../types/ContactMemberships";
import TableMemberships from "./TableMemberships";
import SearchInput from "../../common/Search/SearchInput";
import DistributorComboBox from "./DistributorComboBox";
import useUserHook from "../../../Context/UseUserContext";
import ErrorBoundary from "../../common/ErrorBoundary";

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  edit: ContentContactLists | any;
  independent?: boolean;
}

const DistributorForm = ({
  setIsOpen,
  edit: editProps,
  independent,
}: Props) => {
  const [edit, setEdit] = useState(() => editProps);

  const { t } = useTranslation();

  const formNavigation = useFormNavigation();
  const progressProvider = useProgressHook();
  const useTable = useTableHook();
  const useUser = useUserHook();

  const formRef = useRef<HTMLFormElement | any>();

  const [selectedParent, setSelectedParent] = useState<Item | null>(null);
  const [searchParam, setSearchParam] = useState("");
  const [remarks, setRemarks] = useState("");

  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [parents, setParents] = useState<Item[]>([]);

  const [memberships, setMemberships] = useState<ContactMembershipsT[]>([]);
  const [originalMemberships, setOriginalMemberships] = useState<
    ContactMembershipsT[]
  >([]);
  const [membershipsSearch, setMembershipsSearch] = useState<
    ContactMembershipsT[]
  >([]);
  const [membershipsToDelete, setMembershipsToDelete] = useState<
    string[] | undefined
  >(undefined);
  const [membershipsToAdd, setMembershipsToAdd] = useState<string[]>([]);

  const [contactList, setContactList] = useState<ContentContacts[]>([]);
  const [contactsQuery, setContactsQuery] = useState("");

  const handleCreateDistributor = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setIsLoading(true);

      const form = new FormData(event.target as HTMLFormElement);

      const dataForm = Object.fromEntries(form);

      const bodyContent = JSON.stringify({
        name: dataForm.name,
        parentId: selectedParent?.id ?? "",
        remark: dataForm.remarks,
      });

      if (edit) {
        const membersToDelete = originalMemberships
          .filter(
            (el) =>
              el?.id === membershipsToDelete?.find((el2) => el2 === el?.id)
          )
          .map(
            (el3) =>
              el3?.memberships?.find((el4) => el4?.list?.id === edit?.id)?.id
          );

        const handleMemberships = { membershipsToAdd, membersToDelete };
        //Update Patch
        UpdateFunction(
          "contactLists",
          edit?.id,
          bodyContent,
          undefined,
          null,
          null,
          handleMemberships
        );
      } else {
        //Create
        CreateFunction(
          "contactLists",
          bodyContent,
          undefined,
          null,
          null,
          null,
          membershipsToAdd
        );
      }
      setIsOpen(false);
      // setIsLoading(false);
      setTimeout(() => useTable?.refreshTable(), 1000);
    },
    [
      edit,
      membershipsToAdd,
      membershipsToDelete,
      originalMemberships,
      selectedParent?.id,
      setIsOpen,
      useTable,
    ]
  );

  const handleUpdateProgressContext = useCallback(
    (newProgressData: DistributorMarksT) => {
      progressProvider?.setHandleDistributorMarks(newProgressData);
    },
    []
  );

  const handleResetForm = useCallback(() => {
    if (formRef) {
      formRef?.current?.reset();
      if (edit) {
        const dataFromTable: ContactListsFormData | any = {
          name: true,
          remarks: true,
        };

        Object.keys(dataFromTable).forEach((attribute) => {
          formRef?.current?.[attribute]?.setAttribute("value", "");
        });

        const newProgressData = dataFromTable as DistributorMarksT;

        handleUpdateProgressContext(newProgressData);
      }
      setSelectedParent(null);
      setName("");
      setRemarks("");
    }
  }, []);

  const handleAddMember = useCallback(
    (id?: string, type?: string) => {
      const findRepeated = memberships.find((el) => el.id === id);
      if (!findRepeated) {
        axios
          .get(`${process.env.REACT_APP_DOMAIN}/api/v1/${type}/${id}`, {
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            setMembershipsToAdd((old) => [...old, id!]);
            setMemberships((old) => [...old, response.data]);
          })
          .catch((error) => console.log(error));
      }
    },
    [memberships]
  );

  const handleDeletedMembership = useCallback(
    (membershipsToDelete: string[]) => {
      if (membershipsToDelete?.length) {
        setMemberships((old) =>
          old!?.filter(
            (el) => el.id !== membershipsToDelete.find((el2) => el2 === el.id)
          )
        );

        setMembershipsToAdd((old) =>
          old!?.filter(
            (el) => el !== membershipsToDelete.find((el2) => el2 === el)
          )
        );
      }
    },
    []
  );

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/contactLists`, {
        params: {
          page: 0,
          size: 99999,
          sort: `name,asc`,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setParents(
          response.data.content.map((element: ContentContactLists) => {
            return { id: element.id, name: element.name };
          })
        );
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/contacts`, {
        params: {
          search: contactsQuery,
          size: 5,
          excludeContactListIds: memberships.map((el) => el.id).join(),
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setContactList(
          response.data.content.map((element: ContentContacts) => {
            return {
              ...element,
              type: element?.type === "COMPANY" ? "companies" : "people",
            };
          })
        );
      })
      .catch((error) => console.log(error));
  }, [contactsQuery, memberships]);

  useEffect(() => {
    setIsLoading(true);

    if (edit) {
      axios
        .get(
          `${process.env.REACT_APP_DOMAIN}/api/v1/contactLists/${edit?.id}/memberships`,
          {
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          setOriginalMemberships(response.data);
          setMemberships(response?.data);
          setIsLoading(false);
        })
        .catch((error) => console.log(error));
    }
  }, [edit]);

  useEffect(() => {
    handleDeletedMembership(membershipsToDelete!);
  }, [handleDeletedMembership, membershipsToDelete]);

  useEffect(() => {
    setMembershipsSearch(
      memberships.filter(
        (el) =>
          el?.name?.toLowerCase().includes(searchParam) ||
          el?.personals?.firstName?.toLowerCase().includes(searchParam) ||
          el?.personals?.lastName?.toLowerCase().includes(searchParam) ||
          el?.mainEmail?.toLowerCase().includes(searchParam) ||
          el?.employment?.company?.name?.toLowerCase().includes(searchParam)
      )
    );
  }, [memberships, searchParam]);

  useEffect(() => {
    handleResetForm();

    //Normal inputs
    const dataFromTable: ContactListsFormData | any = {
      name: edit?.name ?? "",
      remarks: edit?.remarks ?? "",
    };

    if (edit) {
      Object.keys(dataFromTable).forEach((attribute) => {
        formRef?.current?.[attribute]?.setAttribute(
          "value",
          dataFromTable[attribute]
        );
      });

      //Name state
      setName(edit?.name);

      //Parent
      if (edit?.parent) {
        const parentId = parents.find(
          (el) => el.name === edit?.parent?.name
        )?.id;

        setSelectedParent({
          id: parentId ?? "",
          name: edit?.parent?.name,
        });
      }

      //remarks
      if (edit?.remarks) {
        setRemarks(edit.remarks);
      }
    }

    // set ProgressBar
    const progressData = Object.entries(
      dataFromTable as ContactListsFormData
    ).reduce((acc, [key, value]) => {
      acc[key] = value.length === 0;
      return acc;
    }, {} as { [key: string]: boolean });

    const newProgressData = progressData as DistributorMarksT;

    handleUpdateProgressContext(newProgressData);
  }, [edit, handleResetForm, handleUpdateProgressContext, parents]);

  useEffect(() => {
    !independent && formNavigation?.setCurrentItem(editProps);
  }, [editProps, independent]);

  useEffect(() => {
    !independent && setEdit(formNavigation?.currentItem);
  }, [editProps, formNavigation?.currentItem, independent]);

  return (
    <>
      {edit ? (
        <ErrorBoundary>
          <FormHeaderNormal
            titleName={`${name}`}
            type="distributor"
            titleTheme="text-theme-distributor"
            subtitle_1={selectedParent?.name}
            id={edit?.id}
          />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary>
          <FormHeaderCreate
            titleName={`${name}`}
            type="distributor"
            titleTheme="text-theme-distributor"
            subtitle_1={selectedParent?.name}
          />
        </ErrorBoundary>
      )}
      <form
        onSubmit={handleCreateDistributor}
        ref={formRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        {/* distributor Detail */}
        <div className="flex flex-col py-6 border-y-[1px] border-divisor">
          <h3 className="text-black font-bold text-xl pb-4">
            {t("form_distributor_title")}
          </h3>
          <div className="flex flex-row justify-start gap-8 w-full">
            <FormInput
              id="name"
              name="name"
              label={t("form_distributor_details_name")}
              customClass="max-w-[292px] w-min-[292px] w-[292px]"
              onBlur={(e) => setName(e.currentTarget.value)}
              required
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
            <SelectForm
              name="parents"
              label={t("form_distributor_details_subdistributor")}
              items={parents}
              value={selectedParent}
              setValue={setSelectedParent}
              doNotMark
              disabled={useUser?.user?.role === "READER"}
            />
          </div>
          <div className="flex flex-row justify-between gap-8 pt-4">
            <FormTextArea
              id="remarks"
              name="remarks"
              label={t("form_distributor_details_remarks")}
              customClass="max-w-[100%] max-h-[109px]"
              defaultValue={remarks}
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
          </div>
        </div>
        {/* manage members */}
        <div className="flex flex-col py-6 border-y-[1px] border-divisor">
          <h3 className="text-black font-bold text-xl pb-4">
            {t("form_distributor_manage_members_title")}
          </h3>
          <div className="flex flex-row justify-between gap-8 w-full">
            <ErrorBoundary>
              <DistributorComboBox
                name="add_members"
                placeholder={t("form_distributor_manage_add_members")}
                data={contactList}
                setQuery={setContactsQuery}
                query={contactsQuery}
                handleAddMember={handleAddMember}
                disabled={useUser?.user?.role === "READER"}
              />
            </ErrorBoundary>
          </div>
          <div className="flex flex-row justify-between gap-8 pt-4">
            <ErrorBoundary>
              <SearchInput
                id="distributor-form"
                name="distributor-form"
                placeholder={t("form_distributor_manage_search_placeholder")}
                customClass="py-2"
                setSearchParam={setSearchParam}
              />
            </ErrorBoundary>
          </div>
          <div className="flex flex-row justify-between gap-8 pt-4">
            <ErrorBoundary>
              <TableMemberships
                data={membershipsSearch}
                defaultData={[]}
                isLoading={isLoading}
                setMembershipsToDelete={setMembershipsToDelete}
              />
            </ErrorBoundary>
          </div>
        </div>
        {edit ? (
          <ErrorBoundary>
            <FormFooterNormal
              formType="contactLists"
              handleResetForm={handleResetForm}
              id={edit?.id}
              name={edit?.name}
              setIsOpenForm={setIsOpen}
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary>
            <FormFooterCreate
              handleResetForm={handleResetForm}
              setIsOpen={setIsOpen}
            />
          </ErrorBoundary>
        )}
      </form>
    </>
  );
};

export default DistributorForm;
