import { InputHTMLAttributes, useEffect, useState } from "react";
import useProgressHook from "../../../Context/UseProgressContext";

interface Props extends InputHTMLAttributes<HTMLElement> {
  name: string;
  id: string;
  placeholder?: string;
  label: string;
  doNotMark?: boolean;
  customClass?: string;
}

const FormDateInput = ({
  name,
  id,
  placeholder,
  label,
  customClass,
  doNotMark = false,
  ...props
}: Props) => {
  const progressContext = useProgressHook();

  const [mark, setMark] = useState(true);

  useEffect(() => {
    if (progressContext?.handleMark(name) === false) {
      setMark(false);
    } else {
      !doNotMark && setMark(true);
    }
  }, [doNotMark, name, progressContext]);

  useEffect(() => {
    if (doNotMark) {
      setMark(false);
    }
  }, [doNotMark]);

  return (
    <div className="flex flex-col justify-start w-full">
      <label className="flex text-base font-bold pb-2">
        {label}{" "}
        {mark && (
          <div className="w-2 h-2 border-2 border-yellow-400 rounded-full bg-yellow-400"></div>
        )}
      </label>
      <div className="flex flex-row gap-4 w-full">
        <input
          {...props}
          type="date"
          name={name}
          id={id}
          placeholder={placeholder ?? ""}
          className={`w-full py-1 px-1 pl-4 border-[1px] border-theme-distributor rounded-lg placeholder:text-center max-w-[145px] ${
            customClass ?? ""
          }`}
          onBlur={(e) => {
            if (e.currentTarget.value === "" && !doNotMark) {
              setMark(true);
              progressContext?.handleStates(name, true);
            } else {
              setMark(false);
              progressContext?.handleStates(name, false);
            }
          }}
        />
      </div>
    </div>
  );
};

export default FormDateInput;
