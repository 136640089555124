import { BookmarkIcon, PersonIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useUserHook from "../../Context/UseUserContext";
import Dropdown from "../common/Dropdown/Dropdown";
import LogoutButton from "../Login/Logout";

const Header = () => {
  const { t } = useTranslation();
  const useUser = useUserHook();

  return (
    <div className="flex justify-between items-center xl:max-w-[1200px] 2xl:max-w-[1640px] w-[100vw] h-16 mt-8 bg-white font-bold text-2xl shadow-theme rounded-lg">
      <Link className="ml-12 flex" to="/">
        <span className="text-black">
          {process.env.REACT_APP_TITLE ?? "Brand Name"}
        </span>
        <div className="flex">
          <span className="px-1 leading-3 text-black text-[10px]">Conea</span>
        </div>
      </Link>
      <div className="flex flex-row mr-12 items-center">
        <Link
          to={"/person"}
          className="px-4 text-xl font-bold text-theme-person border-r-gray-200 border-r-[1px] tracking-normal"
        >
          {t("header_menu_person")}
        </Link>
        <Link
          to={"/company"}
          className="px-4 text-xl font-bold text-theme-company border-r-gray-200 border-r-[1px] tracking-normal"
        >
          {t("header_menu_company")}
        </Link>
        <Link
          to={"/distributor"}
          className="px-4 text-xl font-bold text-theme-distributor border-r-gray-200 border-r-[1px] tracking-normal"
        >
          {t("header_menu_distributor")}
        </Link>

        <Dropdown
          button={
            <button className="flex flex-row px-4 text-[20px] text-black align-middle gap-1 h-full">
              <span className="flex w-2 h-2 rounded-full bg-black"></span>
              <span className="flex w-2 h-2 rounded-full bg-black"></span>
              <span className="flex w-2 h-2 rounded-full bg-black"></span>
            </button>
          }
          children={
            <>
              {(useUser?.user?.role === "ADMIN" ||
                useUser?.user?.role === "OWNER") && (
                <div>
                  <Link
                    to={"/tags"}
                    className={
                      "flex gap-2 cursor-pointer select-none items-center rounded-md px-2 py-2 outline-none text-theme-tags focus:bg-gray-50"
                    }
                  >
                    <BookmarkIcon />
                    {t("header_menu_tags")}
                  </Link>
                  <Link to="/users">
                    <button
                      className={
                        "flex gap-2 cursor-pointer select-none items-center rounded-md px-2 py-2 outline-none text-gray-700 focus:bg-gray-50"
                      }
                    >
                      <PersonIcon />
                      {t("users_link")}
                    </button>
                  </Link>
                </div>
              )}
              <LogoutButton />
            </>
          }
        />
        <Link
          to="/"
          className="flex flex-row px-4 text-[20px] text-black align-middle gap-1 h-full"
        ></Link>
      </div>
    </div>
  );
};

export default Header;
