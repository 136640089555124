import { useTranslation } from "react-i18next";

import SwitchForm from "../../../FormComponents/Switch/SwitchForm";
import {
  EXPORT_COMPANY_ADDRESS,
  EXPORT_COMPANY_CONTACTS,
  EXPORT_COMPANY_DETAILS,
  EXPORT_COMPANY_OTHERS,
} from "../../../../../mocks/EXPORT_COMPANY";

const CompanyExportDialog = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center gap-8 w-full">
      <div className="rounded-xl shadow-lg bg-slate-50 p-4">
        <div>
          <h1 className="font-bold text-lg text-center py-2">
            {t("form_company_details_title")}
          </h1>
          {EXPORT_COMPANY_DETAILS.map((details) => (
            <SwitchForm
              name={details.id}
              label={details.label}
              formType={"company"}
            />
          ))}
        </div>

        <div>
          <h1 className="font-bold text-lg text-center py-2">
            {t("form_person_address_title")}
          </h1>
          {EXPORT_COMPANY_ADDRESS.map((address) => (
            <SwitchForm
              name={address.id}
              label={address.label}
              formType={"company"}
            />
          ))}
        </div>
      </div>

      <div className="rounded-xl shadow-lg bg-slate-50 p-4">
        <div>
          <h1 className="font-bold text-lg text-center py-2">
            {t("form_person_details_title")}
          </h1>
          {EXPORT_COMPANY_CONTACTS.map((contacts) => (
            <SwitchForm
              name={contacts.id}
              label={contacts.label}
              formType={"company"}
            />
          ))}
        </div>
        <div>
          <h1 className="font-bold text-lg text-center py-2">
            {t("export_others_title")}
          </h1>
          {EXPORT_COMPANY_OTHERS.map((others) => (
            <SwitchForm
              name={others.id}
              label={others.label}
              formType={"company"}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyExportDialog;
