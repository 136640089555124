import { Dispatch, FormEvent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import useTableHook from "../../../../Context/UseTableContext";
import CreateFunction from "../../../../lib/CreateFunction";
import FormInput from "../../FormComponents/FormInput";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
const CreateTagsDialog = ({ setIsOpen }: Props) => {
  const { t } = useTranslation();
  const useTable = useTableHook();

  const handleCreate = (e: FormEvent) => {
    e.preventDefault();

    const form = new FormData(e.target as HTMLFormElement);

    const dataForm = Object.fromEntries(form);

    const bodyContent = JSON.stringify({
      value: dataForm.value,
    });
    //Create
    CreateFunction("tags", bodyContent, undefined, null, null, null, null);
    setTimeout(() => useTable?.refreshTable(), 1000);
    setIsOpen(false);
  };

  return (
    <div className="flex flex-row gap-8 px-6 py-4 w-full">
      <form onSubmit={handleCreate} className="flex flex-col gap-4 w-full">
        <h1 className="font-bold">{t("create_tag_dialog_header")}</h1>
        <FormInput
          id="value"
          name="value"
          label={t("create_tag_label")}
          doNotMark
          required
          maxLength={255}
        />
        <div className="flex flex-row gap-4 w-full justify-end">
          <button
            className="border-[1px] border-black bg-white rounded-full text-black font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            {t("users_dialog_cancel_button")}
          </button>
          <button className="border-[1px] border-[#00D975] bg-[#00D975] rounded-full text-white font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300">
            {t("users_dialog_save_button")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTagsDialog;
