interface Props {
  icon: string;
  name: string;
  id: string;
}

const FiledDocuments = ({ icon, name, id }: Props) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <img className="w-14" src={icon} alt="file-icon" />
        <div className="flex flex-col">
          <span className="text-black">{name}</span>
          <a
            className="text-black font-bold underline"
            href={`${process.env.REACT_APP_DOMAIN}/api/v1/attachments/${id}/${name}`}
            target="_blank"
            rel="noreferrer"
          >
            Download
          </a>
        </div>
      </div>
    </div>
  );
};

export default FiledDocuments;
