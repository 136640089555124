import { useTranslation } from "react-i18next";
import { PlusIcon } from "@radix-ui/react-icons";
import { Dialog } from "../../common/Dialog/Dialog";
import { useState } from "react";
import { Link } from "react-router-dom";
import useUserHook from "../../../Context/UseUserContext";

interface Props {
  theme: { bgColor: string; borderColor: string; textColor: string };
  formType: "company" | "person" | "distributor";
}

const ButtonsCard = ({ theme, formType }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const useUser = useUserHook();

  return (
    <div
      className={`flex flex-row justify-start items-center border-t-[1px] border-theme-distributor px-6 py-4 gap-3 -mt-[1px]`}
    >
      {formType === "person" && (
        <>
          {useUser?.user?.role !== "READER"  && (
            <Dialog
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              button={
                <button
                  className={`px-1 py-1 rounded-2xl 2xl:text-base xl:text-sm text-white bg-[#00D975] `}
                >
                  <PlusIcon width={20} height={20} />
                </button>
              }
              formType="person"
            />
          )}
          <Link to="/person">
            <button
              className={`px-2 py-1 rounded-2xl 2xl:text-base xl:text-sm text-white ${theme.bgColor}`}
            >
              {t("home_card_button_message_person")}
            </button>
          </Link>
        </>
      )}
      {formType === "company" && (
        <>
          {useUser?.user?.role !== "READER"  && (
            <Dialog
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              button={
                <button
                  className={`px-1 py-1 rounded-2xl 2xl:text-base xl:text-sm text-white bg-[#00D975] `}
                >
                  <PlusIcon width={20} height={20} />
                </button>
              }
              formType="company"
            />
          )}
          <Link to="/company">
            <button
              className={`px-2 py-1 rounded-2xl 2xl:text-base xl:text-sm text-white ${theme.bgColor}`}
            >
              {t("home_card_button_message_company")}
            </button>
          </Link>
        </>
      )}
      {formType === "distributor" && (
        <>
          {useUser?.user?.role !== "READER"  && (
            <Dialog
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              button={
                <button
                  className={`px-1 py-1 rounded-2xl 2xl:text-base xl:text-sm text-white bg-[#00D975] `}
                >
                  <PlusIcon width={20} height={20} />
                </button>
              }
              formType="distributor"
            />
          )}
          <Link to="/distributor">
            <button
              className={`px-2 py-1 rounded-2xl 2xl:text-base xl:text-sm text-white ${theme.bgColor}`}
            >
              {t("home_card_button_message_distributor")}
            </button>
          </Link>
        </>
      )}
    </div>
  );
};

export default ButtonsCard;
