import { TextareaHTMLAttributes, useEffect, useState } from "react";
import useProgressHook from "../../../Context/UseProgressContext";

interface Props extends TextareaHTMLAttributes<HTMLElement> {
  name: string;
  id: string;
  placeholder?: string;
  label: string;
  customClass?: string;
  doNotMark?: boolean;
}

const FormTextArea = ({
  name,
  id,
  placeholder,
  label,
  customClass,
  doNotMark = false,
  ...props
}: Props) => {
  const progressContext = useProgressHook();

  const [mark, setMark] = useState(true);

  useEffect(() => {
    if (progressContext?.handleMark(name) === false) {
      setMark(false);
    } else {
      !doNotMark && setMark(true);
    }
  }, [doNotMark, name, progressContext]);

  useEffect(() => {
    if (doNotMark) {
      setMark(false);
    }
  }, [doNotMark]);

  return (
    <div className="flex flex-col justify-start w-full">
      <label className="flex text-base font-bold pb-2">
        {label}
        {mark && (
          <div className="w-2 h-2 border-2 border-yellow-400 rounded-full bg-yellow-400"></div>
        )}
      </label>
      <textarea
        {...props}
        name={name}
        id={id}
        placeholder={placeholder ?? ""}
        className={`break-all w-full h-full max-w-[679px] max-h-[195px] min-h-[195px] min-w-[679px] p-4 border-[1px] border-theme-distributor rounded-lg ${
          customClass ?? ""
        }`}
        onBlur={(e) => {
          if (e.currentTarget.value === "" && !doNotMark) {
            !doNotMark && setMark(true);
            !doNotMark && progressContext?.handleStates(name, true);
          } else {
            setMark(false);
            progressContext?.handleStates(name, false);
          }
        }}
      />
    </div>
  );
};

export default FormTextArea;
