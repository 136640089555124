import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { clsx } from "clsx";

interface TooltipProps {
  button: JSX.Element;
  message: string;
}

const Tooltip = ({ button, message }: TooltipProps) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{button}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          side="bottom"
          sideOffset={4}
          className={clsx(
            "radix-side-top:animate-slide-down-fade",
            "radix-side-right:animate-slide-left-fade",
            "radix-side-bottom:animate-slide-up-fade",
            "radix-side-left:animate-slide-right-fade",
            "inline-flex items-center rounded-md px-4 py-2.5",
            "bg-black"
          )}
        >
          <TooltipPrimitive.Arrow className="fill-current text-gray-800" />
          <span className="block text-xs leading-none text-gray-100">
            {message}
          </span>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export { Tooltip };
