import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { clsx } from "clsx";
import { useEffect, useState } from "react";
import { AlertDialog } from "../../common/AlertDialog/AlertDialog";
import DeleteDialog from "../../common/AlertDialog/Dialogs/DeleteDialog";
import { Content as ContentCompaniesT } from "../../../types/Companies";
import { Content as ContentContactListT } from "../../../types/ContactLists";
import { Content as ContentPeopleT } from "../../../types/People";
import { useTranslation } from "react-i18next";
interface DropdownMenuProps {
  button: JSX.Element;
  formType: "company" | "person" | "distributor";
  data: ContentPeopleT | ContentContactListT | ContentCompaniesT | any;
  setIsOpen: (isOpen: boolean) => void;
}

interface DeleteDialogProps {
  id: string;
  tableName: "companies" | "people" | "contactLists";
  message: string;
}

const DeleteDialogAction = ({ id, tableName, message }: DeleteDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-row justify-end gap-2">
      <AlertDialog
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        button={
          <button
            className={clsx(
              "flex cursor-pointer select-none items-center rounded-md px-2 py-2  outline-none",
              "text-gray-400 focus:bg-gray-50"
            )}
          >
            <TrashIcon className="mr-2 h-5 w-5" />
            <span className="flex-grow text-gray-700">Delete</span>
          </button>
        }
      >
        <DeleteDialog
          formType={tableName}
          id={id}
          setIsOpen={setIsOpen}
          message={message}
        />
      </AlertDialog>
    </div>
  );
};

const DropdownHome = ({
  button,
  data,
  formType,
  setIsOpen,
}: DropdownMenuProps) => {
  const [tableName, setTableName] = useState<
    "people" | "companies" | "contactLists"
  >(
    formType === "person"
      ? "people"
      : formType === "company"
      ? "companies"
      : "contactLists"
  );

  const { t } = useTranslation();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (data) {
      if (formType === "person") {
        setMessage(
          `${t("delete_dialog_person_1")} ${data?.personals?.firstName} ${
            data?.personals?.lastName
          } ${t("delete_dialog_person_2")}`
        );
      } else if (formType === "company") {
        setMessage(
          `${t("delete_dialog_company_1")} ${data?.name} ${
            data?.personals?.lastName
          } ${t("delete_dialog_company_2")}`
        );
      } else if (formType === "distributor") {
        setMessage(
          `${t("delete_dialog_distributor_1")} ${data?.name} ${t(
            "delete_dialog_distributor_2"
          )}`
        );
      }
    }
  }, [data]);

  return (
    <div className="relative inline-block text-left">
      <DropdownMenuPrimitive.Root>
        <DropdownMenuPrimitive.Trigger asChild>
          {button}
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Portal>
          <DropdownMenuPrimitive.Content
            align="end"
            sideOffset={5}
            className={clsx(
              "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down",
              "w-fit rounded-lg px-1.5 py-1 shadow-md",
              "bg-white"
            )}
          >
            <DropdownMenuPrimitive.Item
              className={clsx(
                "flex cursor-pointer select-none items-center rounded-md px-2 py-2  outline-none",
                "text-gray-400 focus:bg-gray-50"
              )}
              onClick={() => setIsOpen(true)}
            >
              <Pencil1Icon className="mr-2 h-5 w-5" />
              <span className="flex-grow text-gray-700">Edit</span>
            </DropdownMenuPrimitive.Item>

            <DropdownMenuPrimitive.DropdownMenuSeparator />
            <DeleteDialogAction
              id={data?.id}
              tableName={tableName}
              message={message}
            />
          </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </div>
  );
};

export { DropdownHome };
