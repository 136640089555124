export const IconPerson = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="137.521"
      height="66.734"
      viewBox="0 0 137.521 66.734"
    >
      <g fill="#fff" transform="translate(-2.5 -27)">
        <path
          d="M48.389 54.038c-2.9-3.619-7.238-5.935-11.87-7.238l-.724 1.158a9.871 9.871 0 01-17.082 0l-.724-1.158C9.883 49.116 2.5 54.617 2.5 65.329v9.409a3.213 3.213 0 003.185 3.185h34.6v-5.5a23.99 23.99 0 018.104-18.385z"
          data-name="Pfad 6"
          transform="translate(0 8.862)"
        ></path>
        <path
          d="M100.253 47.9a9.711 9.711 0 01-7.383 3.474H81a9.489 9.489 0 01-7.383-3.474 19.427 19.427 0 00-7.817 5.79 23.47 23.47 0 018.107 18.24v5.5h34.6a3.213 3.213 0 003.185-3.185v-9.408c-.003-8.975-5.07-14.187-11.439-16.937z"
          data-name="Pfad 7"
          transform="translate(28.333 9.355)"
        ></path>
        <path
          d="M76.169 52.767a3.25 3.25 0 003.04 1.737H91.08a3.529 3.529 0 003.04-1.737l5.935-10.278a3.688 3.688 0 000-3.474L94.12 28.737A3.25 3.25 0 0091.08 27H79.209a3.529 3.529 0 00-3.04 1.737l-5.935 10.278a3.688 3.688 0 000 3.474z"
          data-name="Pfad 8"
          transform="translate(30.123)"
        ></path>
        <path
          d="M70.827 52.7a21.135 21.135 0 01-13.173 4.632A20.832 20.832 0 0144.481 52.7C38.111 55.45 32.9 60.807 32.9 69.782v9.409a3.091 3.091 0 003.185 3.04h43.283a3.187 3.187 0 003.185-3.04v-9.409c-.145-8.975-5.212-14.332-11.726-17.082z"
          data-name="Pfad 9"
          transform="translate(13.607 11.503)"
        ></path>
        <circle
          cx="14.91"
          cy="14.91"
          r="14.91"
          data-name="Ellipse 4"
          transform="translate(56.35 32.79)"
        ></circle>
        <path
          d="M21.321 53.736a3.8 3.8 0 006.514 0l12.015-20.99a3.742 3.742 0 00-3.185-5.646H12.346a3.742 3.742 0 00-3.185 5.646z"
          data-name="Pfad 10"
          transform="translate(2.748 .045)"
        ></path>
      </g>
    </svg>
  );
};

export const IconCompany = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="123.722"
      height="66.734"
      data-name="Gruppe 40"
      viewBox="0 0 123.722 66.734"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            d="M0 0H123.722V66.734H0z"
            data-name="Rechteck 12"
          ></path>
        </clipPath>
      </defs>
      <g fill="#fff" clipPath="url(#clip-path)" data-name="Gruppe 20">
        <path
          d="M44 6.442h5.987a2 2 0 010 3.991H44a2 2 0 110-3.991"
          data-name="Pfad 11"
          transform="translate(41.812 6.413)"
        ></path>
        <path
          d="M45 10.442h2a2 2 0 110 3.991h-2a2 2 0 010-3.991"
          data-name="Pfad 12"
          transform="translate(42.807 10.395)"
        ></path>
        <path
          d="M105.244 22.453a2 2 0 00-2.82-.134 1.69 1.69 0 00-.134.134l-2.514 2.774V6.868a2 2 0 00-2-2H79.821v-2a2 2 0 00-1.8-2H45.7a2 2 0 00-1.8 2v17.96h-5.646L21.492.722a2 2 0 00-2.81-.263 2.053 2.053 0 00-.263.263L.459 22.274A2 2 0 000 23.551v41.187a2 2 0 002 2h119.727a2 2 0 002-2V43.566a2 2 0 00-.519-1.357zm-77.307 40.29H11.973V57.1a8.223 8.223 0 017.2-8.321 7.982 7.982 0 018.76 7.982zm15.964 0H39.91V24.828h3.99zm25.942 0H53.879v-5.987a7.982 7.982 0 1115.964 0zM73.834 36.8a2 2 0 01-2 2H51.883a2 2 0 010-3.991h19.956a2 2 0 012 2m0-11.973a2 2 0 01-2 2H51.883a2 2 0 010-3.991h19.956a2 2 0 012 2m0-11.973a2 2 0 01-2 2H51.883a2 2 0 010-3.991h19.956a2 2 0 012 2m9.978 30.711v19.151h-3.996V8.864h15.964v20.753L84.331 42.209a2 2 0 00-.519 1.357m27.937 19.177H95.785v-5.987a7.982 7.982 0 1115.964 0z"
          data-name="Pfad 13"
        ></path>
      </g>
    </svg>
  );
};

export const IconDistributor = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98.572"
      height="71.5"
      data-name="Gruppe 27"
      viewBox="0 0 98.572 71.5"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            d="M0 0H98.572V71.5H0z"
            data-name="Rechteck 14"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)" data-name="Gruppe 26">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M50.942 0a20.015 20.015 0 0119.783 22.993l4.643 1.507a12.7 12.7 0 11-2.194 7.136c0-.322.012-.643.036-.96l-4.5-1.462a20.028 20.028 0 01-14.49 10.531v6.785a12.7 12.7 0 11-6.551 0v-6.785a20 20 0 01-13.2-8.385l-9.42 4.434A12.7 12.7 0 1112.7 26.062a12.665 12.665 0 019.227 3.975l9.757-4.592A20.024 20.024 0 0150.942 0zM12.7 32.613a6.148 6.148 0 106.148 6.148 6.147 6.147 0 00-6.148-6.148m73.174-7.124a6.147 6.147 0 106.148 6.146 6.146 6.146 0 00-6.148-6.146M50.942 52.655a6.147 6.147 0 106.146 6.145 6.145 6.145 0 00-6.146-6.146m0-46.1A13.456 13.456 0 1064.4 20.006 13.456 13.456 0 0050.942 6.551"
          data-name="Pfad 15"
        ></path>
      </g>
    </svg>
  );
};
