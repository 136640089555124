import { createContext, useCallback, useEffect, useState } from "react";
import {
  CompanyMarksT,
  DistributorMarksT,
  PersonMarksT,
} from "../types/global";

type ProgressContextProps = {
  handleCompanyMarks: CompanyMarksT;
  setHandleCompanyMarks: (handleCompanyMarks: CompanyMarksT) => void;

  handlePersonMarks: PersonMarksT;
  setHandlePersonMarks: (handlePersonMarks: PersonMarksT) => void;

  handleDistributorMarks: DistributorMarksT;
  setHandleDistributorMarks: (
    handleDistributorMarks: DistributorMarksT
  ) => void;
  handleStates: (name: string, state: boolean) => void;
  handleMark: (name: string) => boolean | undefined;

  average: number;
  type: string;
  setType: (type: string) => void;
};

export const ProgressContext = createContext<ProgressContextProps | null>(null);

export const ProgressProvider: React.FC<any> = ({ children }) => {
  const [average, setAverage] = useState(0);
  const [type, setType] = useState("");
  const [handleCompanyMarks, setHandleCompanyMarks] = useState({
    branch: true,
    category: true,
    email: true,
    homepage: true,
    name: true,
    place: true,
    remarks: true,
    street: true,
    zipcode: true,
    country: true,
    tags: true,
    contactMethods: true,
  });

  const [handlePersonMarks, setHandlePersonMarks] = useState({
    birthdate: true,
    department: true,
    email: true,
    firstname: true,
    formal: true,
    informal: true,
    lastname: true,
    place: true,
    position: true,
    remarks: true,
    street: true,
    zipcode: true,
    country: true,
    tags: true,
    contactMethods: true,
    company: true,
    prefixedTitle: true,
    suffixedTitle: true,
  });

  const [handleDistributorMarks, setHandleDistributorMarks] = useState({
    name: true,
    remarks: true,
  });

  const getAverage = useCallback(
    (handle: DistributorMarksT | PersonMarksT | CompanyMarksT) => {
      const items = Object.values(handle);

      const total = items.length;

      const marked = items.filter((el) => el === true).length;

      setAverage(((total - marked) / total) * 100);
    },
    []
  );

  const handleStates = useCallback(
    (name: string, state: boolean) => {
      if (type === "company") {
        setHandleCompanyMarks({
          ...handleCompanyMarks,
          [name]: state,
        });
      } else if (type === "person") {
        setHandlePersonMarks({
          ...handlePersonMarks,
          [name]: state,
        });
      } else if (type === "distributor") {
        setHandleDistributorMarks({
          ...handleDistributorMarks,
          [name]: state,
        });
      }
    },
    [handleCompanyMarks, handleDistributorMarks, handlePersonMarks, type]
  );

  const handleMark = useCallback(
    (name: string) => {
      if (type === "company") {
        return (
          handleCompanyMarks?.[name as keyof typeof handleCompanyMarks] ?? null
        );
      } else if (type === "person") {
        return (
          handlePersonMarks?.[name as keyof typeof handlePersonMarks] ?? null
        );
      } else if (type === "distributor") {
        return (
          handleDistributorMarks?.[
            name as keyof typeof handleDistributorMarks
          ] ?? null
        );
      }
    },
    [handleCompanyMarks, handleDistributorMarks, handlePersonMarks, type]
  );

  useEffect(() => {
    if (type === "company") {
      getAverage(handleCompanyMarks);
    }
  }, [getAverage, handleCompanyMarks, type]);

  useEffect(() => {
    if (type === "person") {
      getAverage(handlePersonMarks);
    }
  }, [getAverage, handlePersonMarks, type]);

  useEffect(() => {
    if (type === "distributor") {
      getAverage(handleDistributorMarks);
    }
  }, [getAverage, handleDistributorMarks, type]);

  return (
    <ProgressContext.Provider
      value={{
        handleCompanyMarks,
        setHandleCompanyMarks,
        handlePersonMarks,
        setHandlePersonMarks,
        handleDistributorMarks,
        setHandleDistributorMarks,
        average,
        type,
        setType,
        handleStates,
        handleMark,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};
