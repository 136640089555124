import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { TrashIcon } from "@radix-ui/react-icons";

import Spinner from "../../common/Spinner/Spinner";
import { TagsT, Content } from "../../../types/Tags";
import { t } from "i18next";
import MainTable from "../../common/MainTable/MainTable";
import { Dispatch, SetStateAction, useState } from "react";
import { SortState } from "../../../types/global";
import { AlertDialog } from "../../common/AlertDialog/AlertDialog";
import FormButton from "../../common/FormComponents/FormButton";
import DeleteDialog from "../../common/AlertDialog/Dialogs/DeleteDialog";
import EmptyResults from "../../common/EmptyState/EmptyResults";
import EditTagsDialog from "../../common/AlertDialog/Dialogs/EditTagsDialog";
import ErrorBoundary from "../../common/ErrorBoundary";

interface RowActionsProps {
  name: string;
}

const columnHelper = createColumnHelper<Content>();

const RowActions = ({ name }: RowActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-row justify-end gap-2">
      <AlertDialog
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        button={
          <FormButton
            customClass="h-6 w-6"
            theme={{
              borderColor: "border-red-600",
              bgColor: "",
              textColor: "",
            }}
          >
            <TrashIcon className="w-3 h-3 text-red-600" />
          </FormButton>
        }
      >
        <DeleteDialog
          formType="tags"
          id={encodeURI(name)}
          setIsOpen={setIsOpen}
          message={`${t("delete_dialog_tags_1")} ${name} ${t(
            "delete_dialog_tags_2"
          )}`}
        />
      </AlertDialog>
    </div>
  );
};

interface RowEditDialogProps {
  oldTag: string;
}

const RowEditDialog = ({ oldTag }: RowEditDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AlertDialog
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      button={<button type="button">{oldTag}</button>}
    >
      <EditTagsDialog id={encodeURI(oldTag)} setIsOpen={setIsOpen} />
    </AlertDialog>
  );
};

const columns = [
  columnHelper.accessor((row) => row?.value, {
    id: "value",
    cell: (props) => <RowEditDialog oldTag={props.cell.getValue()} />,
    header: () => <span>{t("table_distributor_header_name")}</span>,
  }),
  columnHelper.display({
    id: "actions",
    header: () => (
      <span className="text-right">
        {t("table_distributor_header_quickaction")}
      </span>
    ),
    cell: (props) => <RowActions name={props.cell.row.original.value} />,
  }),
];

interface Props {
  data: TagsT | null;
  isLoading: boolean;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  defaultData: never[];
  setSort: Dispatch<SetStateAction<SortState>>;
  sort: {
    sortName: string;
    sortValue: string;
  };
}
const TagsTable = ({
  data,
  isLoading,
  setPagination,
  pagination,
  defaultData,
  setSort,
  sort,
}: Props) => {
  const table = useReactTable({
    data: data?.content ?? defaultData,
    columns,
    pageCount: data?.totalPages ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  if (!data && isLoading) {
    return (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-theme-distributor" size="h-20 w-20" />
      </div>
    );
  }

  if (!data?.content.length && !isLoading) {
    return <EmptyResults />;
  }

  return (
    <div className="relative py-2 px-12 w-full flex justify-between flex-col">
      <ErrorBoundary>
        <MainTable
          table={table}
          setSort={setSort}
          sort={sort}
          sizesPage={[50, 100, 200]}
        />
      </ErrorBoundary>
      {isLoading ? (
        <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-10 top-0 left-0">
          <Spinner customClass="fill-theme-distributor" size="h-20 w-20" />
        </div>
      ) : null}
    </div>
  );
};

export default TagsTable;
