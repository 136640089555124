import { ChevronDownIcon } from "@radix-ui/react-icons";
import { clsx } from "clsx";
import React from "react";

type Props = Omit<React.ComponentProps<"button">, "className"> & {
  customClass?: string | undefined;
};

const SelectFormButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, customClass, ...props }, ref) => (
    <button
      ref={ref}
      {...props}
      className={clsx(
        "h-auto w-full inline-flex select-none items-center justify-between rounded-lg py-1 px-4",
        `bg-white text-theme-black hover:bg-gray-50  hover:border-gray-500 border-[1px] border-theme-distributor`,
        "focus:outline-none focus-visible:ring focus-visible:ring-gray-200 focus-visible:ring-opacity-75",
        `${customClass ?? ""}`,
        // Register all radix states
        "group",
        "radix-state-open:bg-gray-50",
        "radix-state-on:bg-gray-50",
        "radix-state-instant-open:bg-gray-50 radix-state-delayed-open:bg-gray-50"
      )}
    >
      {children}
      <ChevronDownIcon />
    </button>
  )
);

SelectFormButton.displayName = "SelectFormButton";
export default SelectFormButton;
