import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GetQuickFilters from "../../../lib/GetQuickFilters";
import { Content as ContentCompaniesT } from "../../../types/Companies";
import { Content as ContentContactListT } from "../../../types/ContactLists";
import { Content as ContentPeopleT } from "../../../types/People";
import { Filter } from "../../common/QuickFilters/QuickFilters";
import ButtonsCard from "./ButtonsCard";
import LatestCard from "./LatestCard";
import ErrorBoundary from "../../common/ErrorBoundary";

interface Props {
  theme: { bgColor: string; borderColor: string; textColor: string };
  title: string;
  icon: JSX.Element;
  formType: "company" | "person" | "distributor";
  data?: ContentPeopleT[] | ContentContactListT[] | ContentCompaniesT[] | any;
}

const CardHome = ({ theme, title, icon, formType, data }: Props) => {
  const { t } = useTranslation();
  const [quickFilters, setQuickFilters] = useState<Filter[] | null>(null);

  useEffect(() => {
    if (formType === "person" || formType === "company") {
      GetQuickFilters(formType === "person" ? "people" : "companies")
        .then((quickFilters) => {
          setQuickFilters(quickFilters as any);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [formType]);

  return (
    <div
      className={`flex flex-col w-full xl:max-w-[350px] 2xl:max-w-[492px] max-w-[492px] border-2 ${theme.borderColor} rounded-lg`}
    >
      <div
        className={`flex flex-row justify-between ${theme.bgColor} p-6 min-h-[192px]`}
      >
        <div className="flex flex-col w-full p-2">
          <h3 className="text-white 2xl:text-2xl xl:text-lg mb-6">{title}</h3>
          {formType !== "distributor" && (
            <div className="flex flex-col text-white">
              <div className="flex flex-row justify-between ">
                <span className="xl:text-xs 2xl:text-base ">
                  {t("home_card_subtitles_1")}
                </span>
                <span className="xl:text-xs 2xl:text-base">
                  {quickFilters &&
                    quickFilters.find((el) => el.name === "ALL")?.value}
                </span>
              </div>
              <div className="flex flex-row justify-between">
                <span className="xl:text-xs 2xl:text-base">
                  {t("home_card_subtitles_2")}
                </span>
                <span className="xl:text-xs 2xl:text-base">
                  {quickFilters &&
                    quickFilters.find((el) => el.name === "INCOMPLETE")?.value}
                </span>
              </div>
              <div className="flex flex-row justify-between">
                <span className="xl:text-xs 2xl:text-base">
                  {t("home_card_subtitles_3")}
                </span>
                <span className="xl:text-xs 2xl:text-base">
                  {quickFilters &&
                    quickFilters.find((el) => el.name === "DUPLICATES")?.value}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <span className="lg:w-24 2xl:w-full flex">{icon}</span>
        </div>
      </div>
      <div className="flex flex-col px-6 ">
        {formType !== "distributor" && (
          <h3
            className={`${theme.textColor} 2xl:text-xl xl:text-md font-bold py-4`}
          >
            {`${t("home_card_latest_title")} ${t(title)}`}
          </h3>
        )}
        {formType === "person" && (
          <>
            {data?.map((el: ContentPeopleT) => (
              <ErrorBoundary>
                <LatestCard
                  key={el?.id}
                  id={el?.id}
                  formType={formType}
                  title={`${el?.personals?.firstName} ${el?.personals?.lastName}`}
                  subtitle={`${el?.mainEmail}`}
                  data={data}
                />
              </ErrorBoundary>
            ))}
          </>
        )}
        {formType === "company" && (
          <>
            {data?.map((el: ContentCompaniesT) => (
              <ErrorBoundary>
                <LatestCard
                  id={el?.id}
                  key={el?.id}
                  formType={formType}
                  title={`${el?.name}`}
                  subtitle={`${el?.mainEmail}`}
                  data={data}
                />
              </ErrorBoundary>
            ))}
          </>
        )}
      </div>
      <ErrorBoundary>
        <ButtonsCard theme={theme} formType={formType} />
      </ErrorBoundary>
    </div>
  );
};

export default CardHome;
