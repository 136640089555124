import { Component } from "react";
import ErrorDialog from "./AlertDialog/Dialogs/ErrorDialog";
import { AlertDialog } from "./AlertDialog/AlertDialog";
import { t } from "i18next";

interface Props {
  children: JSX.Element;
  fallback?: JSX.Element;
}

interface State {
  hasError: boolean;
  isOpen: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      isOpen: false,
    };
    this.setIsOpen = this.setIsOpen.bind(this);
  }

  setIsOpen(value: boolean) {
    this.setState({
      isOpen: value,
    });
  }

  componentDidCatch(error: Error, info: any) {
    console.error(error);
    this.setState({ hasError: true, isOpen: true });
  }

  render() {
    const { fallback, children } = this.props;
    const { hasError, isOpen } = this.state;

    if (hasError) {
      return (
        <>
          <AlertDialog
            key="error-dialog"
            setIsOpen={this.setIsOpen as any}
            isOpen={isOpen}
            button={<></>}
          >
            <ErrorDialog
              setIsOpen={this.setIsOpen as any}
              message={t("error_dialog_message")}
            />
          </AlertDialog>
          {fallback}
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
