import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Cross2Icon, EnvelopeOpenIcon } from "@radix-ui/react-icons";
import FormInput from "../../common/FormComponents/FormInput";
import {
  Item,
  SelectForm,
} from "../../common/FormComponents/SelectForm/SelectForm";
import FormTextArea from "../../common/FormComponents/FormTextArea";
import FormDateInput from "../../common/FormComponents/FormDateInput";
import FormInputTags from "../../common/FormComponents/FormInputTags";
import { getList } from "country-list-with-dial-code-and-flag";
import {
  ContactMethodsT,
  GdprT,
  PersonFormData,
  PersonMarksT,
} from "../../../types/global";
import ContactFormInput from "../../common/FormComponents/ContactMethods/ContactFormInput";
import ContactMethods from "../../common/FormComponents/ContactMethods/ContactMethods";
import FormHeaderCreate from "../../common/FormComponents/FormHeader/FormHeaderCreate";
import FormFooterCreate from "../../common/FormComponents/FormFooter/FormFooterCreate";
import axios from "axios";
import { Content as CompanyContent } from "../../../types/Companies";
import { Dialog } from "../../common/Dialog/Dialog";
import UpdateFunction from "../../../lib/UpdateFunction";
import FormHeaderNormal from "../../common/FormComponents/FormHeader/FormHeaderNormal";
import CreateFunction from "../../../lib/CreateFunction";
import useProgressHook from "../../../Context/UseProgressContext";
import useTableHook from "../../../Context/UseTableContext";
import { Content, Membership } from "../../../types/People";
import Memberships from "../../common/FormComponents/Memberships/Memberships";
import useFormNavigation from "../../../Context/UseFormNavigationContext";
import FormFooterNormal from "../../common/FormComponents/FormFooter/FormFooterNormal";
import { PREFIXED } from "../../../mocks/PREFIXED";
import { SUFFIXED } from "../../../mocks/SUFFIXED";
import useUserHook from "../../../Context/UseUserContext";
import CompanyComboBox from "../../common/FormComponents/CompanyComboBox";
import PhoneContact from "../../common/FormComponents/ContactMethods/PhoneContact";
import ErrorBoundary from "../../common/ErrorBoundary";

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  edit: Content | any;
  selectedPerson?: string;
  independent?: boolean;
}

const PersonForm = ({
  setIsOpen,
  edit: editProps,
  selectedPerson,
  independent = false,
}: Props) => {
  const [edit, setEdit] = useState(() => editProps);

  useEffect(() => {
    if (selectedPerson) {
      axios
        .get(
          `${process.env.REACT_APP_DOMAIN}/api/v1/people/${selectedPerson}`,
          {
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          setEdit(response?.data);
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  }, [selectedPerson]);

  const { t } = useTranslation();
  const progressProvider = useProgressHook();
  const useTable = useTableHook();
  const formNavigation = useFormNavigation();
  const useUser = useUserHook();

  const formRef = useRef<HTMLFormElement | any>();

  const [tags, setTags] = useState<string[]>([]);
  const [contactMethods, setContactMethods] = useState<ContactMethodsT[]>([]);

  const [prefixedTitle, setPrefixedTitle] = useState<Item | null>(null);
  const [suffixedTitle, setSuffixedTitle] = useState<Item | null>(null);
  const [country, setCountry] = useState<Item | null>(null);
  const [remarks, setRemarks] = useState("");

  const [name, setName] = useState({ firstname: "", lastname: "" });
  const [employment, setEmployment] = useState({
    position: "",
    department: "",
  });
  const [companyInfo, setcompanyInfo] = useState<CompanyContent | null>(null);

  const [companies, setCompanies] = useState<Item[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Item | null>(null);
  const [openCreateCompany, setOpenCreateCompany] = useState(false);
  const [openEditCompany, setOpenEditCompany] = useState(false);
  const [companyQuery, setCompanyQuery] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [selectedContactLists, setSelectedContactLists] = useState<Item | null>(
    null
  );
  const [selectedMembership, setSelectedMembership] = useState<
    Membership | undefined
  >(undefined);

  const [consentDate, setConsentDate] = useState("");
  const [selectedGdpr, setSelectedGdpr] = useState<GdprT | null>(null);

  const [attachments, setAttachments] = useState<File[] | null>(null);

  const [customFields, setCustomfields] = useState<string[] | null>(null);

  const countryList = useMemo(() => {
    return getList().map((country, index) => {
      return { id: index.toString(), name: country.code };
    });
  }, []);

  useEffect(() => {
    if (selectedCompany?.id) {
      axios
        .get(
          `${process.env.REACT_APP_DOMAIN}/api/v1/companies/${selectedCompany?.id}`,
          {
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          setcompanyInfo(response?.data);
        })
        .catch((error) => console.log(error));
    }
  }, [selectedCompany?.id]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/companies`, {
        params: {
          page: 0,
          size: 5,
          sort: `name,asc`,
          search: companyQuery,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setCompanies(
          response.data.content.map((element: CompanyContent) => {
            return {
              id: element.id,
              name: element.name === "" ? element.mainEmail : element.name,
            };
          })
        );
      })
      .catch((error) => console.log(error));
  }, [openCreateCompany, companyQuery]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/customFields`, {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setCustomfields(response?.data.map((el: string) => el.trim()));
      })
      .catch((error) => console.log(error));
  }, []);

  const handleCreatePerson = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setIsLoading(true);

      const form = new FormData(event.target as HTMLFormElement);

      const dataForm = Object.fromEntries(form);

      //pick up the custom fields from from data.
      const customFieldsBody = customFields?.reduce((acc, key) => {
        if (dataForm.hasOwnProperty(key)) {
          acc[key] = dataForm[key];
        }
        return acc;
      }, {} as Record<string, any>);

      const bodyContent = JSON.stringify({
        mainEmail: dataForm.email,
        remarks: dataForm.remarks,
        address: {
          streetName: dataForm.street,
          place: dataForm.place,
          zipCode: dataForm.zipcode,
          country: country?.name ?? "",
        },
        personals: {
          gender: "UNKNOWN",
          informalSalutation: dataForm.informal,
          firstName: dataForm.firstname,
          lastName: dataForm.lastname,
          prefixedTitle: prefixedTitle?.name,
          suffixedTitle: suffixedTitle?.name,
          dateOfBirth: dataForm.birthdate ?? "",
        },
        employment: {
          position: dataForm.position,
          department: dataForm.department,
          companyId: selectedCompany?.id ?? "",
        },
        customFields: customFieldsBody ?? {},
      });

      if (edit) {
        const pathCM = contactMethods.filter((previousValue) => {
          const findEl = edit.contactMethods.find(
            (el: ContactMethodsT) => el.id === previousValue.id
          );

          return (
            previousValue?.value !== findEl?.value &&
            previousValue?.id === findEl?.id
          );
        });

        const postCM = contactMethods.filter((previousValue) => {
          const findEl = edit.contactMethods.find(
            (el: ContactMethodsT) => el.id === previousValue.id
          );

          return previousValue?.id !== findEl?.id;
        });

        const deleteCM = edit.contactMethods.filter(
          (previousValue: ContactMethodsT) => {
            const findEl = contactMethods.find(
              (el: ContactMethodsT) => el.id === previousValue.id
            );

            return previousValue?.id !== findEl?.id;
          }
        );

        const postTag = tags.filter((tag: string) => {
          return edit.tags.indexOf(tag) === -1;
        });

        const deleteTag = edit.tags.filter((tag: string) => {
          return tags.indexOf(tag) === -1;
        });

        const updates = { pathCM, postCM, deleteCM, postTag, deleteTag };

        const membershipFunctions = {
          selectedContactLists,
          selectedMembership,
          consentDate,
          selectedGdpr,
          attachments,
        };
        //Update Patch
        UpdateFunction(
          "people",
          edit?.id,
          bodyContent,
          setSelectedCompany,
          updates,
          membershipFunctions
        );
      } else {
        const membershipFunctions = {
          selectedContactLists,
          consentDate,
          selectedGdpr,
        };

        //Create
        CreateFunction(
          "people",
          bodyContent,
          setSelectedCompany,
          contactMethods,
          tags,
          membershipFunctions
        );
      }

      setIsOpen(false);
      setIsLoading(false);
      setTimeout(() => useTable?.refreshTable(), 1000);
    },
    [
      country?.name,
      prefixedTitle?.name,
      suffixedTitle?.name,
      selectedCompany?.id,
      edit,
      setIsOpen,
      contactMethods,
      tags,
      selectedMembership,
      consentDate,
      selectedGdpr,
      attachments,
      selectedContactLists,
      useTable,
      customFields,
    ]
  );

  const handleDeleteContact = useCallback(
    (selectedContact: ContactMethodsT) => {
      setContactMethods(
        contactMethods.filter((contact) => contact.id !== selectedContact.id)
      );
    },
    [contactMethods]
  );

  const handleAdditionContact = useCallback(
    (contact: ContactMethodsT) => {
      setContactMethods([...contactMethods, contact]);
    },
    [contactMethods]
  );

  const handleUpdateContact = useCallback(
    (selectedContact: ContactMethodsT, newValue: string) => {
      const newState = structuredClone(contactMethods);

      newState.find((el) => el.id === selectedContact.id)!.value = newValue;

      setContactMethods(newState);
    },
    [contactMethods]
  );

  const handleDeleteTag = useCallback(
    (selectedTag: string) => {
      setTags(tags.filter((tag) => tag !== selectedTag));
    },
    [tags]
  );

  const handleAdditionTag = useCallback(
    (tag: string) => {
      if (!tags.find((el) => el === tag)) {
        setTags([...tags, tag]);
      }
    },
    [tags]
  );

  const handleUpdateProgressContext = useCallback(
    (newProgressData: PersonMarksT) => {
      progressProvider?.setHandlePersonMarks(newProgressData);
    },
    []
  );

  const handleResetForm = useCallback(() => {
    if (formRef) {
      formRef?.current?.reset();
      if (edit) {
        const dataFromTable: PersonFormData | any = {
          birthdate: true,
          department: true,
          email: true,
          firstname: true,
          formal: true,
          informal: true,
          lastname: true,
          place: true,
          position: true,
          remarks: true,
          street: true,
          zipcode: true,
        };

        Object.keys(dataFromTable).forEach((attribute) => {
          formRef?.current?.[attribute]?.setAttribute("value", "");
        });

        const newProgressData = {
          ...(dataFromTable as PersonMarksT),
          contactMethods: true,
          tags: true,
          country: true,
          company: true,
          prefixedTitle: true,
          suffixedTitle: true,
        };

        handleUpdateProgressContext(newProgressData);
      }
      setName({ firstname: "", lastname: "" });
      setEmployment({ department: "", position: "" });
      setTags([]);
      setContactMethods([]);
      setPrefixedTitle(null);
      setSuffixedTitle(null);
      setCountry(null);
      setSelectedCompany(null);
      setRemarks("");
    }
  }, [edit, handleUpdateProgressContext]);

  useEffect(() => {
    setIsLoading(true);
    handleResetForm();

    //Normal inputs
    const dataFromTable: PersonFormData | any = {
      birthdate: edit?.personals?.dateOfBirth ?? "",
      department: edit?.employment?.department ?? "",
      email: edit?.mainEmail ?? "",
      firstname: edit?.personals?.firstName ?? "",
      formal: edit?.personals?.formalSalutation ?? "",
      informal: edit?.personals?.informalSalutation ?? "",
      lastname: edit?.personals?.lastName ?? "",
      place: edit?.address?.place ?? "",
      position: edit?.employment?.position ?? "",
      remarks: edit?.remarks ?? "",
      street: edit?.address?.streetName ?? "",
      zipcode: edit?.address?.zipCode ?? "",
    };
    if (edit) {
      Object.keys(dataFromTable).forEach((attribute) => {
        formRef?.current?.[attribute]?.setAttribute(
          "value",
          dataFromTable[attribute]
        );
      });

      //Name state
      setName({
        firstname: `${edit?.personals?.firstName ?? ""}`,
        lastname: `${edit?.personals?.lastName ?? ""}`,
      });

      // set position and dpt
      setEmployment({
        position: edit?.employment?.position,
        department: edit?.employment?.department,
      });

      //prefixed and suffixed
      if (edit?.personals?.prefixedTitle) {
        const prefixedTitleId = PREFIXED.find(
          (el) => el.name === edit?.personals?.prefixedTitle
        )?.id;

        setPrefixedTitle({
          id: prefixedTitleId ?? "",
          name: edit?.personals?.prefixedTitle,
        });
      }
      if (edit?.personals?.suffixedTitle) {
        const suffixedTitleId = SUFFIXED.find(
          (el) => el.name === edit?.personals?.suffixedTitle
        )?.id;

        setSuffixedTitle({
          id: suffixedTitleId ?? "",
          name: edit?.personals?.suffixedTitle,
        });
      }

      //Country
      if (edit?.address?.country) {
        const countryId = countryList.find(
          (el) => el.name === edit?.address?.country
        )?.id;

        setCountry({
          id: countryId ?? "",
          name: edit?.address?.country,
        });
      }

      //Contact Methods
      if (edit?.contactMethods) {
        setContactMethods(edit?.contactMethods);
      }

      //Tags
      if (edit?.tags) {
        setTags(edit?.tags);
      }

      //remarks
      if (edit?.remarks) {
        setRemarks(edit.remarks);
      }

      // set company
      if (edit?.employment?.company?.id && !selectedCompany?.id) {
        setSelectedCompany({
          id: edit?.employment?.company?.id,
          name:
            edit?.employment?.company?.name === ""
              ? edit?.employment?.company?.mainEmail
              : edit?.employment?.company?.name,
        });
      }
    }

    // set ProgressBar

    const progressData = Object.entries(dataFromTable as PersonFormData).reduce(
      (acc, [key, value]) => {
        acc[key] = value.length === 0;
        return acc;
      },
      {} as { [key: string]: boolean }
    );

    const newProgressData = {
      ...(progressData as PersonMarksT),
      contactMethods: edit?.contactMethods ? false : true,
      tags: edit?.tags ? false : true,
      country: edit?.address?.country ? false : true,
      company: edit?.employment?.company?.name ? false : true,
      prefixedTitle: edit?.personals?.prefixedTitle ? false : true,
      suffixedTitle: edit?.personals?.suffixedTitle ? false : true,
    };

    handleUpdateProgressContext(newProgressData);
    setIsLoading(false);
  }, [
    countryList,
    edit,
    handleResetForm,
    handleUpdateProgressContext,
    openEditCompany,
  ]);

  useEffect(() => {
    if (!selectedPerson) {
      if (independent === false) {
        formNavigation?.setCurrentItem(editProps);
      }
    }
  }, [editProps, selectedPerson, independent]);

  useEffect(() => {
    if (!selectedPerson) {
      if (independent === false) {
        setEdit(formNavigation?.currentItem);
      }
    }
  }, [editProps, formNavigation?.currentItem, handleResetForm, independent]);

  useEffect(() => {
    setTimeout(() => progressProvider?.setType("person"), 500);
  }, [openEditCompany, openCreateCompany]);

  return (
    <>
      {edit ? (
        <ErrorBoundary>
          <FormHeaderNormal
            titleName={`${name.firstname} ${name.lastname} `}
            type="person"
            subtitle_1={employment.position}
            subtitle_2={employment.department}
            titleTheme="text-theme-person"
            isDoubleDialog={selectedPerson ? true : false}
            id={edit?.id}
            contacts={{
              email: edit?.mainEmail,
              phone:
                contactMethods?.find((el) => el.type === "PHONE")?.value ??
                undefined,
            }}
          />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary>
          <FormHeaderCreate
            titleName={`${name.firstname} ${name.lastname} `}
            type="person"
            titleTheme="text-theme-person"
          />
        </ErrorBoundary>
      )}
      <form
        onSubmit={handleCreatePerson}
        ref={formRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        {/* Person Detail */}
        <div className="flex flex-col py-6 border-y-[1px] border-divisor">
          <h3 className="text-black font-bold text-xl pb-4">
            {t("form_person_details_title")}
          </h3>
          <div className="flex flex-row justify-start gap-x-16 gap-y-4 flex-wrap">
            <FormInput
              id="email"
              name="email"
              label={t("form_person_details_label_email")}
              fullWidth
              type="email"
              required
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
              linkButton={
                edit?.mainEmail && (
                  <a
                    href={`mailto:${edit.mainEmail}`}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer flex gap-2 items-center"
                  >
                    <EnvelopeOpenIcon
                      className="text-gray-400"
                      width={20}
                      height={20}
                    />
                  </a>
                )
              }
            />

            {!contactMethods.find((el) => el.type === "PHONE") && (
              <ErrorBoundary>
                <PhoneContact
                  handleAdditionContact={handleAdditionContact}
                  handleUpdateContact={handleUpdateContact}
                  disabled={useUser?.user?.role === "READER"}
                />
              </ErrorBoundary>
            )}

            {contactMethods.map((contact) => (
              <ErrorBoundary>
                <ContactFormInput
                  handleDeleteContact={handleDeleteContact}
                  key={contact.id}
                  contact={contact}
                  handleUpdateContact={handleUpdateContact}
                  disabled={useUser?.user?.role === "READER"}
                />
              </ErrorBoundary>
            ))}
            <ErrorBoundary>
              <ContactMethods
                handleAdditionContact={handleAdditionContact}
                marked={contactMethods.length === 0 && edit}
                disabled={useUser?.user?.role === "READER"}
              />
            </ErrorBoundary>
          </div>
        </div>
        {/* Person Data */}
        <div className="flex flex-col py-6 border-y-[1px] border-divisor">
          <h3 className="text-black font-bold text-xl pb-4">
            {t("form_person_data_title")}
          </h3>
          <div className="flex flex-row justify-between gap-8">
            <FormInput
              id="formal"
              name="formal"
              label={t("form_person_data_label_formal")}
              fullWidth
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
            <FormInput
              id="informal"
              name="informal"
              label={t("form_person_data_label_informal")}
              fullWidth
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
          </div>
          <div className="flex flex-row justify-between gap-8 pt-4">
            <FormInput
              id="firstname"
              name="firstname"
              label={t("form_person_data_label_firstname")}
              fullWidth
              onBlurCapture={(e) =>
                setName({ ...name, firstname: e.currentTarget.value })
              }
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
            <FormInput
              id="lastname"
              name="lastname"
              label={t("form_person_data_label_lastname")}
              fullWidth
              onBlurCapture={(e) => {
                setName({ ...name, lastname: e.currentTarget.value });
              }}
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
            <div className="flex flex-row gap-12 relative">
              <SelectForm
                name="prefixedTitle"
                label={t("form_person_data_label_prefixedTitle")}
                items={PREFIXED}
                value={prefixedTitle}
                setValue={setPrefixedTitle}
                customClass="max-w-[147px] min-w-[147px]"
                key="prefixedTitle"
                doNotMark={!edit}
                disabled={useUser?.user?.role === "READER"}
              />
              <SelectForm
                name="suffixedTitle"
                label={t("form_person_data_label_suffixedTitle")}
                items={SUFFIXED}
                value={suffixedTitle}
                setValue={setSuffixedTitle}
                customClass="max-w-[147px] min-w-[147px]"
                key="suffixedTitle"
                doNotMark={!edit}
                disabled={useUser?.user?.role === "READER"}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between gap-8 pt-4">
            <FormTextArea
              name="remarks"
              id="remarks"
              label={t("form_person_data_label_remarks")}
              defaultValue={remarks}
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
            <div className="flex flex-col w-full break-all">
              <ErrorBoundary>
                <FormInputTags
                  id="keywords"
                  name="keywords"
                  label={t("form_person_data_label_keywords")}
                  placeholder={t("form_person_data_keywords_placeholder") ?? ""}
                  handleAdditionTag={handleAdditionTag}
                  marked={tags.length === 0 && edit}
                  disabled={useUser?.user?.role === "READER"}
                />
              </ErrorBoundary>
              <div className="flex flex-row gap-2 flex-wrap py-4">
                {tags
                  .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
                  .map((tag) => (
                    <div
                      className="flex flex-row items-center gap-4 bg-black rounded-2xl text-white w-fit px-3 py-1"
                      key={tag}
                    >
                      #{tag}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (useUser?.user?.role !== "READER") {
                            handleDeleteTag(tag);
                          }
                        }}
                      >
                        <Cross2Icon />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between gap-8 pt-4">
            <div className="flex flex-row w-full ">
              <FormDateInput
                id="birthdate"
                name="birthdate"
                label={t("form_person_data_label_birth")}
                doNotMark={!edit}
                disabled={useUser?.user?.role === "READER"}
              />
            </div>
          </div>
        </div>

        {/* Custom Fields Data*/}
        {customFields && (
          <div className="flex flex-col py-6 border-y-[1px] border-divisor">
            <h3 className="text-black font-bold text-xl pb-4">
              {t("custom_fields_section_title")}
            </h3>
            <ErrorBoundary>
              <div className="flex flex-row justify-start gap-8 flex-wrap">
                {customFields.map((el) => (
                  <FormInput
                    key={el}
                    id={el}
                    name={el}
                    label={el}
                    doNotMark={true}
                    disabled={useUser?.user?.role === "READER"}
                    customClass={"min-w-[326px]"}
                    defaultValue={edit?.customFields[el] ?? ""}
                  />
                ))}
              </div>
            </ErrorBoundary>
          </div>
        )}

        {/* Address Data*/}
        <div className="flex flex-col py-6 border-y-[1px] border-divisor">
          <h3 className="text-black font-bold text-xl pb-4">
            {t("form_person_address_title")}
          </h3>
          <div className="flex flex-row justify-between gap-8">
            <FormInput
              id="street"
              name="street"
              label={t("form_person_address_street")}
              fullWidth
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
              customClass={"w-[480px]"}
            />
            <FormInput
              id="place"
              name="place"
              label={t("form_person_address_place")}
              fullWidth
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
              customClass={"w-[240px]"}
            />
            <FormInput
              id="zipcode"
              name="zipcode"
              label={t("form_person_address_zipcode")}
              fullWidth
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
            <SelectForm
              name="country"
              label={t("form_company_address_country")}
              items={countryList}
              value={country}
              setValue={setCountry}
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
          </div>
        </div>
        {/* Business Data */}
        <div className="flex flex-col py-6 border-y-[1px] border-divisor">
          <h3 className="text-black font-bold text-xl pb-4">
            {t("form_person_business_data_title")}
          </h3>
          <div className="flex flex-row justify-between gap-8">
            <ErrorBoundary>
              <CompanyComboBox
                fullWidth
                items={companies}
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                disabled={useUser?.user?.role === "READER"}
                setQuery={setCompanyQuery}
                query={companyQuery}
              />
            </ErrorBoundary>
            <Dialog
              isOpen={openEditCompany}
              setIsOpen={setOpenEditCompany}
              button={
                <button
                  className="border-[1px] border-black bg-white rounded-full text-base text-black font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300"
                  disabled={
                    selectedCompany === null || useUser?.user?.role === "READER"
                  }
                >
                  {t("form_person_business_data_edit")}
                </button>
              }
              formType="company"
              edit={companyInfo ?? null}
              setSelectedCompany={setSelectedCompany}
            />

            <Dialog
              isOpen={openCreateCompany}
              setIsOpen={setOpenCreateCompany}
              button={
                <button
                  className="border-[1px] border-[#00D975] bg-white rounded-full text-base text-[#00D975] font-bold px-2 py-1 h-full self-end min-w-[137px]"
                  disabled={useUser?.user?.role === "READER"}
                >
                  {t("form_person_business_data_new")}
                </button>
              }
              formType="company"
              edit={null}
              setSelectedCompany={setSelectedCompany}
            />
          </div>
          <div className="flex flex-row justify-between gap-8 pt-4">
            <FormInput
              id="position"
              name="position"
              label={t("form_person_business_data_label_position")}
              fullWidth
              onBlurCapture={(e) =>
                setEmployment({
                  ...employment,
                  position: e.currentTarget.value,
                })
              }
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
            <FormInput
              id="department"
              name="department"
              label={t("form_person_business_data_label_department")}
              fullWidth
              onBlurCapture={(e) =>
                setEmployment({
                  ...employment,
                  department: e.currentTarget.value,
                })
              }
              doNotMark={!edit}
              disabled={useUser?.user?.role === "READER"}
            />
          </div>
        </div>
        <div className="flex flex-col py-6 border-y-[1px] border-divisor">
          <h3 className="text-black font-bold text-xl pb-4">
            {t("membership_title")}
          </h3>
          <ErrorBoundary>
            <Memberships
              memberships={edit?.memberships}
              setSelectedContactLists={setSelectedContactLists}
              selectedContactLists={selectedContactLists}
              setSelectedMembership={setSelectedMembership}
              attachments={attachments}
              consentDate={consentDate}
              selectedMembership={selectedMembership}
              setAttachments={setAttachments}
              setConsentDate={setConsentDate}
              setSelectedGdpr={setSelectedGdpr}
              selectedGdpr={selectedGdpr}
            />
          </ErrorBoundary>
        </div>
        {edit ? (
          <ErrorBoundary>
            <FormFooterNormal
              formType="people"
              handleResetForm={handleResetForm}
              id={edit?.id}
              name={`${edit?.personals?.firstName} ${edit?.personals?.lastName}`}
              setIsOpenForm={setIsOpen}
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary>
            <FormFooterCreate
              handleResetForm={handleResetForm}
              setIsOpen={setIsOpen}
            />
          </ErrorBoundary>
        )}
      </form>
    </>
  );
};

export default PersonForm;
