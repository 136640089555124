import { Dispatch, SetStateAction, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import {
  EnvelopeOpenIcon,
  PinBottomIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import {
  Content,
  Content as PeopleContent,
  PeopleT,
} from "../../../types/People";
import Spinner from "../../common/Spinner/Spinner";
import { t } from "i18next";
import MainTable from "../../common/MainTable/MainTable";
import { AlertDialog } from "../../common/AlertDialog/AlertDialog";
import DeleteDialog from "../../common/AlertDialog/Dialogs/DeleteDialog";
import FormButton from "../../common/FormComponents/FormButton";
import { Dialog } from "../../common/Dialog/Dialog";
import EmptyResults from "../../common/EmptyState/EmptyResults";
import ErrorBoundary from "../../common/ErrorBoundary";

export interface SortState {
  sortName: string;
  sortValue: string;
}

interface RowActionsProps {
  id: string;
  name: string;
}

const columnHelper = createColumnHelper<PeopleContent>();

const RowActions = ({ id, name }: RowActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-row justify-end gap-2">
      <button className="w-6 h-6 border-[1px] border-theme-distributor rounded-full flex items-center justify-center">
        <PinBottomIcon className="w-3 h-3 text-theme-distributor" />
      </button>
      <AlertDialog
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        button={
          <FormButton
            customClass="h-6 w-6"
            theme={{
              borderColor: "border-red-600",
              bgColor: "",
              textColor: "",
            }}
          >
            <TrashIcon className="w-3 h-3 text-red-600" />
          </FormButton>
        }
      >
        <DeleteDialog
          formType="people"
          id={id}
          setIsOpen={setIsOpen}
          message={`${t("delete_dialog_person_1")} ${name} ${t(
            "delete_dialog_person_2"
          )}`}
        />
      </AlertDialog>
    </div>
  );
};

interface RowEditDialogProps {
  row: Content;
  button: JSX.Element;
}

const RowEditDialog = ({ row, button }: RowEditDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      button={button}
      formType="person"
      edit={row}
    />
  );
};

const columns = [
  columnHelper.accessor((row) => row?.personals?.lastName, {
    id: "personals.lastName",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        button={<span className="cursor-pointer">{props.cell.getValue()}</span>}
      />
    ),
    header: () => <span>{t("table_person_header_lastname")}</span>,
  }),
  columnHelper.accessor((row) => row?.personals?.firstName, {
    id: "personals.firstName",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        button={<span className="cursor-pointer">{props.cell.getValue()}</span>}
      />
    ),
    header: () => <span>{t("table_person_header_firstname")}</span>,
  }),
  columnHelper.accessor((row) => row?.employment?.company?.name, {
    id: "employment.company.name",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        button={<span className="cursor-pointer">{props.cell.getValue()}</span>}
      />
    ),
    header: () => <span>{t("table_person_header_companyname")}</span>,
  }),
  columnHelper.accessor((row) => row?.mainEmail, {
    id: "mainEmail",
    header: () => <span>{t("table_person_header_email")}</span>,
    cell: (props) => (
      <a
        href={`mailto:${props.cell.getValue()}`}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer flex gap-2 items-center"
      >
        {props.cell.getValue() && (
          <EnvelopeOpenIcon className="text-gray-400" width={18} height={18} />
        )}
        {props.cell.getValue()}
      </a>
    ),
  }),
  columnHelper.display({
    id: "actions",
    header: () => (
      <span className="text-right">{t("table_person_header_quickaction")}</span>
    ),
    cell: (props) => (
      <RowActions
        id={props.cell.row.original.id}
        name={`${props?.cell?.row?.original?.personals?.firstName} ${props?.cell?.row?.original?.personals?.lastName}`}
      />
    ),
  }),
];

interface Props {
  data: PeopleT | null;
  isLoading: boolean;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  defaultData: never[];
  setSort: Dispatch<SetStateAction<SortState>>;
  sort: {
    sortName: string;
    sortValue: string;
  };
}
const PersonTable = ({
  data,
  isLoading,
  setPagination,
  pagination,
  defaultData,
  setSort,
  sort,
}: Props) => {
  const table = useReactTable({
    data: data?.content ?? defaultData,
    columns,
    pageCount: data?.totalPages ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  if (!data && isLoading) {
    return (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-theme-person" size="h-20 w-20" />
      </div>
    );
  }

  if (!data?.content.length && !isLoading) {
    return <EmptyResults />;
  }

  return (
    <div className="relative py-2 px-12 w-full flex justify-between flex-col">
      <ErrorBoundary>
        <MainTable
          table={table}
          setSort={setSort}
          sort={sort}
          sizesPage={[50, 100, 200]}
        />
      </ErrorBoundary>
      {isLoading ? (
        <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-10 top-0 left-0">
          <Spinner customClass="fill-theme-person" size="h-20 w-20" />
        </div>
      ) : null}
    </div>
  );
};

export default PersonTable;
