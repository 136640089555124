import { InputHTMLAttributes } from "react";
import { ContactMethodsT } from "../../../../types/global";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  handleAdditionContact: (contact: ContactMethodsT) => void;
  handleUpdateContact: (
    selectedContact: ContactMethodsT,
    value: string
  ) => void;
}

const PhoneContact = ({
  handleAdditionContact,
  handleUpdateContact,
  ...props
}: Props) => {
  return (
    <div
      className={`flex flex-col justify-start w-full max-w-[309px] min-w-[309px]`}
    >
      <label className="text-base font-bold pb-2 capitalize">Phone</label>
      <div className="flex items-center gap-2">
        <input
          {...props}
          onBlur={(e) => {
            if (e.currentTarget.value.length >= 3) {
              handleAdditionContact({
                type: "PHONE",
                value: e.currentTarget.value,
                id: `PHONE-${e.currentTarget.value}`,
              });
            }
          }}
          onKeyDown={(e) => {
            if (e.currentTarget.value.length >= 3) {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAdditionContact({
                  type: "PHONE",
                  value: e.currentTarget.value,
                  id: `PHONE-${e.currentTarget.value}`,
                });
              }
            }
          }}
          className={`w-full py-1 px-1 pl-4 border-[1px] border-theme-distributor rounded-lg`}
        />
      </div>
    </div>
  );
};

export default PhoneContact;
