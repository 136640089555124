import { t } from "i18next";

export const EXPORT_PERSON_OTHERS = [
  { id: "tags", label: t("form_person_data_label_keywords") },
  { id: "remarks", label: t("form_person_data_label_remarks") },
];

export const EXPORT_PERSON_PERSONALS = [
  { id: "mainEmail", label: `Main ${t("form_person_details_label_email")}` },
  { id: "personals.firstName", label: t("form_person_data_label_firstname") },
  { id: "personals.lastName", label: t("form_person_data_label_lastname") },
  {
    id: "personals.informalSalutation",
    label: t("form_person_data_label_informal"),
  },
  {
    id: "personals.prefixedTitle",
    label: t("form_person_data_label_prefixedTitle"),
  },
  {
    id: "personals.suffixedTitle",
    label: t("form_person_data_label_suffixedTitle"),
  },
  { id: "personals.dateOfBirth", label: t("form_person_data_label_birth") },
];

export const EXPORT_PERSON_ADDRESS = [
  { id: "streetName", label: t("form_person_address_street") },
  { id: "place", label: t("form_person_address_place") },
  { id: "zipCode", label: t("form_person_address_zipcode") },
  { id: "country", label: t("form_company_address_country") },
];

export const EXPORT_PERSON_CONTACTS = [
  {
    id: "contactMethods.EMAIL",
    label: "E-mail",
  },
  {
    id: "contactMethods.PHONE",
    label: "Phone",
  },
  {
    id: "contactMethods.TWITTER",
    label: "Twitter",
  },
  {
    id: "contactMethods.FACEBOOK",
    label: "Facebook",
  },
  {
    id: "contactMethods.INSTAGRAM",
    label: "Instagram",
  },
  {
    id: "contactMethods.PRIVATE_PHONE",
    label: t("contactType_private_phone"),
  },
  {
    id: "contactMethods.MOBILE",
    label: t("contactType_mobile"),
  },
  {
    id: "contactMethods.FAX",
    label: t("contactType_fax"),
  },
  {
    id: "contactMethods.UNKNOWN",
    label: "Unknown",
  },
];

export const EXPORT_PERSON_EMPLOYMENT = [
  {
    id: "employment.department",
    label: t("form_person_business_data_label_department"),
  },
  {
    id: "employment.position",
    label: t("form_person_business_data_label_position"),
  },
  {
    id: "employment.company.name",
    label: t("form_person_business_data_medium"),
  },
  {
    id: "employment.company.email",
    label: "E-mail",
  },
];
