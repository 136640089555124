import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Person } from "../../../types/Companies";
import Spinner from "../../common/Spinner/Spinner";
import { t } from "i18next";
import MainTable from "../../common/MainTable/MainTable";
import { Content } from "../../../types/People";
import { useEffect, useState } from "react";
import { Dialog } from "../../common/Dialog/Dialog";
import ErrorBoundary from "../../common/ErrorBoundary";

interface RowEditDialogProps {
  row: Content | any;
  value: string;
}

const columnHelper = createColumnHelper<Person>();

const RowEditDialog = ({ row, value }: RowEditDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      button={<span className="cursor-pointer">{value}</span>}
      formType="person"
      edit={null}
      selectedPerson={row?.id}
    />
  );
};

const columns = [
  columnHelper.accessor((row) => row?.personals?.firstName, {
    id: "firstName",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        value={props.cell.getValue()}
      />
    ),
    header: () => <span>{t("table_person_header_firstname")}</span>,
  }),
  columnHelper.accessor((row) => row?.personals?.lastName, {
    id: "lastName",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        value={props.cell.getValue()}
      />
    ),
    header: () => <span>{t("table_person_header_lastname")}</span>,
  }),
  columnHelper.accessor((row) => row?.employment?.position, {
    id: "position",
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        value={props.cell.getValue()}
      />
    ),
    header: () => <span>{t("form_person_business_data_label_position")}</span>,
  }),
  columnHelper.accessor((row) => row?.mainEmail, {
    id: "mainEmail",
    header: () => <span>{t("table_person_header_email")}</span>,
    cell: (props) => (
      <RowEditDialog
        row={props.cell.row.original}
        value={props.cell.getValue()}
      />
    ),
  }),
];

interface Props {
  data: Person[] | null;
  isLoading: boolean;
  defaultData: never[];
}
const TableContacts = ({ data, isLoading, defaultData }: Props) => {
  const table = useReactTable({
    data: data ?? defaultData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  if (!data) {
    return (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-theme-company" size="h-20 w-20" />
      </div>
    );
  }

  return (
    <div className="relative py-2 w-full flex justify-between flex-col">
      <ErrorBoundary>
        <MainTable table={table} />
      </ErrorBoundary>
      {isLoading ? (
        <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-10 top-0 left-0">
          <Spinner customClass="fill-theme-company" size="h-20 w-20" />
        </div>
      ) : null}
    </div>
  );
};

export default TableContacts;
