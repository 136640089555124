import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import useTableHook from "../../../../Context/UseTableContext";
import DeleteFunctions from "../../../../lib/DeleteFunction";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
  formType: "companies" | "people" | "contactLists" | "users" | "tags";
  message: string;
  setIsOpenForm?: (value: boolean) => void;
  setMembershipsToDelete?: Dispatch<SetStateAction<string[] | undefined>>;
}
const DeleteDialog = ({
  setIsOpen,
  id,
  formType,
  message,
  setIsOpenForm,
  setMembershipsToDelete,
}: Props) => {
  const useTable = useTableHook();
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-8 px-6 py-4">
      <div className="flex flex-col items-center justify-start mt-4">
        <ExclamationTriangleIcon className="w-16 h-16" />
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="font-bold">{t("delete_dialog_header")}</h1>
        <p>{message}</p>
        <div className="flex flex-row gap-4">
          <button
            className="border-[1px] border-black bg-white rounded-full text-black font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300"
            onClick={() => setIsOpen(false)}
          >
            {t("delete_dialog_gray_button")}
          </button>
          <button
            onClick={() => {
              if (setMembershipsToDelete) {
                setMembershipsToDelete((old) => [
                  ...new Set(!old?.length ? [id] : [...old!, id]),
                ]);
              } else {
                DeleteFunctions(id, formType);
                setTimeout(() => useTable?.refreshTable(), 1000);
              }
              setIsOpen(false);
              setIsOpenForm && setIsOpenForm(false);
            }}
            className="border-[1px] border-[#FF2B4B] bg-[#FF2B4B] rounded-full text-white font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300"
          >
            {t("delete_dialog_red_button")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDialog;
