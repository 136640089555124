import { Combobox } from "@headlessui/react";
import axios from "axios";
import { MouseEvent, useEffect, useState } from "react";
import useProgressHook from "../../../Context/UseProgressContext";
import { Content } from "../../../types/Tags";

interface Props {
  name: string;
  id: string;
  placeholder?: string;
  label: string;
  handleAdditionTag: (tag: string) => void;
  marked: boolean;
  disabled?: boolean;
}

const FormInputTags = ({
  name,
  id,
  placeholder,
  label,
  handleAdditionTag,
  marked,
  disabled = false,
}: Props) => {
  const [data, setData] = useState<Content[]>();
  const [query, setQuery] = useState("");
  const progressContext = useProgressHook();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN}/api/v1/tags`, {
        params: {
          page: 0,
          size: 25,
          sort: `value,asc`,
          search: query,
        },
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setData(response.data.content);
      })
      .catch((error) => console.log(error));
  }, [query]);

  useEffect(() => {
    if (marked) {
      progressContext?.handleStates("tags", true);
    } else {
      progressContext?.handleStates("tags", false);
    }
  }, [marked]);

  return (
    <div className="flex flex-col justify-start w-full">
      <label className="flex gap-1 text-base font-bold pb-2">
        {label}{" "}
        {marked && (
          <div className="w-2 h-2 border-2 border-yellow-400 rounded-full bg-yellow-400"></div>
        )}
      </label>
      <Combobox disabled={disabled}>
        <Combobox.Input
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          name={name}
          id={id}
          placeholder={placeholder ?? ""}
          className={`w-full py-1 px-1 pl-4 border-[1px] border-theme-distributor rounded-lg`}
          autoComplete="off"
        />
        {data && (
          <Combobox.Options className="border-[1px] border-theme-distributor rounded-md mt-1 overflow-y-auto max-h-[200px]">
            {data?.map((tag) => (
              <Combobox.Option
                key={tag.value}
                value={tag.value}
                className={`rounded-lg border-theme-distributor px-2 py-1 font-bold hover:bg-theme-distributor`}
                onClick={(
                  e: MouseEvent<HTMLButtonElement, MouseEvent> | any
                ) => {
                  e.preventDefault();
                  handleAdditionTag(tag.value);
                  setQuery("");
                }}
              >
                {tag.value}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
};

export default FormInputTags;
