import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GetQuickFilters from "../../../lib/GetQuickFilters";
import useTableHook from "../../../Context/UseTableContext";

export interface Filter {
  name: string;
  value: string;
}

interface Props {
  formType: "people" | "companies";
  theme: {
    bgColor: string;
    borderColor: string;
    textColor: string;
    bgHover: string;
  };
  setSelectedQuickFilter: Dispatch<SetStateAction<string>>;
  selectedQuickFilter: string;
  search: string;
  tags: string;
  filtering: string;
  companyIds?: string;
}

const QuickFilters = ({
  theme,
  setSelectedQuickFilter,
  selectedQuickFilter,
  formType,
  search,
  tags,
  filtering,
  companyIds = "",
}: Props) => {
  const { t } = useTranslation();
  const [quickFilters, setQuickFilters] = useState<Filter[] | null>(null);
  const useTable = useTableHook();

  useEffect(() => {
    const filters = { search, tags, filtering, companyIds };
    GetQuickFilters(formType, filters)
      .then((quickFilters) => {
        setQuickFilters(quickFilters as any);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [filtering, formType, search, tags, companyIds, useTable?.refresh]);

  if (!quickFilters) {
    return <></>;
  }

  return (
    <div className="flex flex-row gap-4">
      {quickFilters.map((filter) => (
        <div
          key={`${filter.name}-${filter.value}`}
          className={`group/item peer border-[1px] border-theme-distributor rounded-lg flex flex-col items-center px-2 py-1 min-w-[135px] cursor-pointer ${
            theme.bgHover
          } ${selectedQuickFilter === filter.name && theme.bgColor}`}
          onClick={() => {
            setSelectedQuickFilter(filter.name);
          }}
        >
          <p
            className={`2xl:text-2xl xl:text-xl ${
              theme.textColor
            } font-bold group-hover/item:text-white ${
              selectedQuickFilter === filter.name && "text-white"
            }`}
          >
            {filter.value}
          </p>

          <p
            className={`2xl:text-md xl:text-xs text-black group-hover/item:text-white ${
              selectedQuickFilter === filter.name && "text-white"
            }`}
          >
            {t(`quickfilters_${filter.name}`)}
          </p>
        </div>
      ))}
    </div>
  );
};

export default QuickFilters;
