import axios from "axios";

type Filters = {
  search: string;
  tags: string;
  filtering: string;
  companyIds: string;
};
async function GetQuickFilters(
  tableName: "companies" | "people" | "contactLists",
  filters?: Filters
) {
  const quickFilters = ["ALL", "INCOMPLETE", "DUPLICATES", "NEW", "REMOVED"];

  const headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  try {
    const responseData = await Promise.all(
      quickFilters.map(async (filter) => {
        const params = {
          quickFilter: filter,
          size: "1",
          ...filters,
        };

        const reqOptions = {
          url: `${process.env.REACT_APP_DOMAIN}/api/v1/${tableName}`,
          method: "GET",
          headers: headersList,
          params,
        };

        const data = await axios
          .request(reqOptions)
          .then((response) => response.data);

        return { name: filter, value: data.totalElements };
      })
    );

    return responseData;
  } catch (err) {
    throw err;
  }
}

export default GetQuickFilters;
