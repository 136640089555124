import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ComponentType } from "react";
import Spinner from "../common/Spinner/Spinner";

interface AuthenticationGuardProps {
  component: ComponentType<any>;
}

export const AuthenticationGuard = ({
  component,
}: AuthenticationGuardProps) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="fixed inset-0 z-20 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-20 top-0 left-0">
        <Spinner customClass="fill-black" size="h-20 w-20" />
      </div>
    ),
  });

  return <Component />;
};
