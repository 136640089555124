import { InputHTMLAttributes, useEffect, useState } from "react";
import useProgressHook from "../../../../Context/UseProgressContext";
import { ContactMethodsT } from "../../../../types/global";
import { SelectContactMethod } from "./SelectContactMethod";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  handleAdditionContact: (contact: ContactMethodsT) => void;
  marked: boolean;
}

const ContactMethods = ({ handleAdditionContact, marked, ...props }: Props) => {
  const [type, setType] = useState<string>("");

  const progressContext = useProgressHook();

  useEffect(() => {
    if (marked) {
      progressContext?.handleStates("contactMethods", true);
    } else {
      progressContext?.handleStates("contactMethods", false);
    }
  }, [marked]);

  return (
    <div className={`w-full flex flex-col max-w-[309px] min-w-[309px]`}>
      <SelectContactMethod setType={setType} type={type} marked={marked} />
      <input
        {...props}
        onBlur={(e) => {
          if (e.currentTarget.value.length >= 3 && type !== "") {
            handleAdditionContact({
              type: type,
              value: e.currentTarget.value,
              id: `${type}-${e.currentTarget.value}`,
            });
            e.currentTarget.value = "";
            setType("");
          }
        }}
        onKeyDown={(e) => {
          if (e.currentTarget.value.length >= 3) {
            if (e.key === "Enter") {
              e.preventDefault();
              handleAdditionContact({
                type: type,
                value: e.currentTarget.value,
                id: `${type}-${e.currentTarget.value}`,
              });
              e.currentTarget.value = "";
              setType("");
            }
          }
        }}
        className={`w-full py-1 px-1 pl-4 border-[1px] border-theme-distributor rounded-lg`}
      />
    </div>
  );
};

export default ContactMethods;
