export const ROLES = [
  { id: "1", name: "EDITOR" },
  { id: "2", name: "READER" },
  { id: "3", name: "ADMIN" },
  { id: "4", name: "OWNER" },
];

export const ROLES_OPTION_OWNER = [
  { id: "1", name: "ADMIN" },
  { id: "2", name: "EDITOR" },
  { id: "3", name: "READER" },
];

export const ROLES_OPTION_ADMIN = [
  { id: "1", name: "EDITOR" },
  { id: "2", name: "READER" },
];
