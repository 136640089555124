import { InputHTMLAttributes, useEffect, useState } from "react";
import useProgressHook from "../../../Context/UseProgressContext";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id: string;
  placeholder?: string;
  label: string;
  customClass?: string;
  fullWidth?: boolean;
  doNotMark?: boolean;
  linkButton?: JSX.Element;
}
const FormInput = ({
  name,
  id,
  placeholder,
  label,
  customClass,
  fullWidth,
  doNotMark = false,
  linkButton,
  ...props
}: Props) => {
  const progressContext = useProgressHook();

  const [mark, setMark] = useState(true);

  useEffect(() => {
    if (progressContext?.handleMark(name) === false) {
      setMark(false);
    } else {
      !doNotMark && setMark(true);
    }
  }, [doNotMark, name, progressContext]);

  useEffect(() => {
    if (doNotMark) {
      setMark(false);
    }
  }, [doNotMark]);

  return (
    <div
      className={`flex flex-col justify-start ${
        fullWidth ? "w-full" : "w-auto"
      }`}
    >
      <label className="flex flex-row items-start text-base font-bold pb-2 gap-1">
        {label}
        {mark && (
          <div className="w-2 h-2 border-2 border-yellow-400 rounded-full bg-yellow-400"></div>
        )}
      </label>
      <div className="flex gap-2 items-center">
        {linkButton}
        <input
          {...props}
          name={name}
          id={id}
          placeholder={placeholder ?? ""}
          className={`w-full py-1 px-1 pl-4 border-[1px] border-theme-distributor rounded-lg ${
            customClass ?? ""
          }`}
          onBlur={(e) => {
            if (e.currentTarget.value === "" && !doNotMark) {
              !doNotMark && setMark(true);
              !doNotMark && progressContext?.handleStates(name, true);
            } else {
              !doNotMark && setMark(false);
              !doNotMark && progressContext?.handleStates(name, false);
            }
          }}
        />
      </div>
    </div>
  );
};

export default FormInput;
