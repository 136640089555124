import { createContext, useEffect, useState } from "react";

import axios from "axios";
import { AlertDialog } from "../components/common/AlertDialog/AlertDialog";
import RequestErrorDialog from "../components/common/AlertDialog/Dialogs/RequestErrorDialog";
import { useTranslation } from "react-i18next";

interface RequestErrorContextProps {
  retry: boolean;
  setRetry: (retry: boolean) => void;
}

export const RequestErrorContext =
  createContext<RequestErrorContextProps | null>(null);

export const RequestErrorContextProvider: React.FC<any> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [retry, setRetry] = useState(false);
  const [oldRequest, setOldRequest] = useState<any>(undefined);

  const { t } = useTranslation();

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error) {
        setIsOpen(true);
      }
      setOldRequest(error.config);
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (retry) {
      axios.request(oldRequest);
    }
    setRetry(false);
  }, [oldRequest, retry]);

  return (
    <RequestErrorContext.Provider value={{ retry, setRetry }}>
      <AlertDialog setIsOpen={setIsOpen} isOpen={isOpen} button={<></>}>
        <RequestErrorDialog
          setIsOpen={setIsOpen}
          message={t("requestError_dialog_message")}
        />
      </AlertDialog>
      {children}
    </RequestErrorContext.Provider>
  );
};
