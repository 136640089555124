import { Dispatch, FormEvent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import PersonExportDialog from "./PersonExportDialog";
import CompanyExportDialog from "./CompanyExportDialog";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  formType: "company" | "person";
  handleCsv: (selectedFields: string) => void;
}

const ExportDialog = ({ setIsOpen, formType, handleCsv }: Props) => {
  const { t } = useTranslation();

  const handleCsvFields = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const form = new FormData(event.target as HTMLFormElement);

    const dataForm = Object.fromEntries(form);

    const selectedFields = Object.keys(dataForm).join(",");

    setIsOpen(false);

    handleCsv(selectedFields);
    
  };

  return (
    <div className="flex flex-row gap-8 px-6 py-4 w-full">
      <form onSubmit={handleCsvFields} className="flex flex-col gap-8 w-full">
        <h1 className="font-bold text-2xl">{t("export_dialog_title")}</h1>

        {formType === "company" ? (
          <CompanyExportDialog />
        ) : (
          <PersonExportDialog />
        )}

        <div className="flex w-full gap-4 justify-end">
          <button className="border-[1px] border-[#00D975] bg-[#00D975] rounded-full text-white font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300">
            {t("export_dialog_download")}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
            className="border-[1px] border-black bg-white rounded-full text-black font-bold px-2 py-1 h-full self-end min-w-[137px] disabled:text-gray-300 disabled:border-gray-300"
          >
            {t("export_dialog_cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ExportDialog;
