import { ButtonHTMLAttributes } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: JSX.Element;
  theme?: { bgColor: string; borderColor: string; textColor: string };
  customClass?: string;
}

const FormButton = ({
  name,
  id,
  children,
  theme,
  customClass,
  ...props
}: Props) => {
  return (
    <button
      {...props}
      className={`border-[1px] rounded-full flex items-center justify-center ${
        theme?.borderColor ?? ""
      } ${theme?.bgColor ?? ""} ${theme?.textColor ?? ""} ${customClass ?? ""}`}
    >
      {children}
    </button>
  );
};

export default FormButton;
