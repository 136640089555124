import { Cross1Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import * as DialogPrimitive from "@radix-ui/react-dialog";

interface Props {
  titleName: string;
  type: "person" | "company" | "distributor";
  subtitle_1?: string;
  subtitle_2?: string;
  titleTheme: string;
}

const FormHeaderCreate = ({
  titleName,
  type,
  subtitle_1,
  subtitle_2,
  titleTheme,
}: Props) => {
  return (
    <>
      <div className="w-full flex flex-row justify-end gap-4">
        <DialogPrimitive.Close
          className={clsx(
            "inline-flex items-center justify-center border-[1px] border-theme-distributor rounded-full p-2",
            "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
          )}
        >
          <Cross1Icon className="h-4 w-4 text-theme-distributor hover:text-gray-700" />
        </DialogPrimitive.Close>
      </div>
      <div className="flex flex-row w-full pb-8">
        <div className="flex flex-col w-full justify-start">
          <h1 className={`text-4xl ${titleTheme} font-bold py-4`}>
            {titleName}
          </h1>
          {(type === "person" || type === "distributor") && (
            <span className="font-bold text-base">{subtitle_1}</span>
          )}
          {type === "person" && <span className="text-base">{subtitle_2}</span>}
        </div>
      </div>
    </>
  );
};

export default FormHeaderCreate;
