import { useAuth0 } from "@auth0/auth0-react";
import { ExitIcon } from "@radix-ui/react-icons";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button
      className={
        "flex gap-2 cursor-pointer select-none items-center rounded-md px-2 py-2 outline-none text-gray-700 focus:bg-gray-50"
      }
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      <ExitIcon />
      Logout
    </button>
  );
};

export default LogoutButton;
