import {
  CaretSortIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import * as SelectPrimitive from "@radix-ui/react-select";
import { clsx } from "clsx";
import { Dispatch, SetStateAction } from "react";
import { t } from "i18next";

type SelectContactMethodT = {
  setType: Dispatch<SetStateAction<string>>;
  type: string;
  marked: boolean;
};

export const types = [
  { key: "UNKNOWN", label: "Unknown" },
  { key: "EMAIL", label: "E-Mail" },
  { key: "PHONE", label: "Phone" },
  { key: "TWITTER", label: "Twitter" },
  { key: "FACEBOOK", label: "Facebook" },
  { key: "INSTAGRAM", label: "Instagram" },
  { key: "PRIVATE_PHONE", label: t("contactType_private_phone") },
  { key: "MOBILE", label: t("contactType_mobile") },
  { key: "FAX", label: t("contactType_fax") },
];

const SelectContactMethod = ({
  setType,
  type,
  marked,
}: SelectContactMethodT) => {
  if (!types) {
    return <></>;
  }

  return (
    <div className="flex flex-col justify-start w-full">
      <SelectPrimitive.Root onValueChange={setType} value={type}>
        <SelectPrimitive.Trigger asChild aria-label="Food">
          <span className="text-base font-bold pb-2 cursor-pointer flex gap-1 items-center">
            <SelectPrimitive.Value className="text-left">
              {type === ""
                ? t("form_person_data_select_placeholder")
                : types.find((typeElement) => typeElement.key === type)?.label}
            </SelectPrimitive.Value>
            <CaretSortIcon />
            {marked && (
              <div className="w-2 h-2 border-2 border-yellow-400 rounded-full bg-yellow-400 self-start"></div>
            )}
          </span>
        </SelectPrimitive.Trigger>
        <SelectPrimitive.Portal className="z-50">
          <SelectPrimitive.Content align="center">
            <SelectPrimitive.ScrollUpButton className="flex items-center justify-center text-gray-700">
              <ChevronUpIcon />
            </SelectPrimitive.ScrollUpButton>
            <SelectPrimitive.Viewport className="bg-white p-2 rounded-lg shadow-lg">
              <SelectPrimitive.Group>
                {types.map((f, i) => (
                  <SelectPrimitive.Item
                    key={`${f}-${i}`}
                    value={f.key}
                    className={clsx(
                      "relative flex items-center px-8 py-2 rounded-md text-sm text-gray-700 font-medium focus:bg-gray-100",
                      "radix-disabled:opacity-50",
                      "focus:outline-none select-none"
                    )}
                  >
                    <SelectPrimitive.ItemText>
                      {f.label}
                    </SelectPrimitive.ItemText>
                    <SelectPrimitive.ItemIndicator /* className="absolute left-2 inline-flex items-center" */
                    >
                      <CheckIcon />
                    </SelectPrimitive.ItemIndicator>
                  </SelectPrimitive.Item>
                ))}
              </SelectPrimitive.Group>
            </SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className="flex items-center justify-center text-gray-700">
              <ChevronDownIcon />
            </SelectPrimitive.ScrollDownButton>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    </div>
  );
};

export { SelectContactMethod };
