import axios from "axios";
import { Item } from "../components/common/FormComponents/SelectForm/SelectForm";
import { ContactMethodsT, GdprT } from "../types/global";
import { Membership } from "../types/People";

type UpdatesT = {
  pathCM: ContactMethodsT[];
  postCM: ContactMethodsT[];
  deleteCM: ContactMethodsT[];
  postTag: string[];
  deleteTag: string[];
};

type MembershipFunctionsT = {
  selectedMembership: Membership | undefined;
  consentDate: string;
  selectedGdpr: GdprT | null;
  attachments: File[] | null;
  selectedContactLists: Item | null;
};

type MembershipUpdatesT = {
  membershipsToAdd?: string[];
  membersToDelete?: (string | undefined)[];
};

async function UpdateFunction(
  tableName: "companies" | "people" | "contactLists",
  id: string,
  bodyContent: string,
  setSelectedCompany?: ((selectedCompany: Item) => void | null) | undefined,
  updates?: UpdatesT | null,
  membershipFunctions?: MembershipFunctionsT | null,
  membershipUpdates?: MembershipUpdatesT | null
) {
  const headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  const reqOptions = {
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/${tableName}/${id}`,
    method: "PATCH",
    headers: headersList,
    data: bodyContent,
  };
  const responseData = axios
    .request(reqOptions)
    .then((tableResponse) => {
      if (tableName === "companies" && setSelectedCompany) {
        setSelectedCompany({
          id: tableResponse.data.id,
          name: tableResponse.data.name,
        });
      }

      // Contact Methods
      // Delete Contact Methods
      if (updates?.deleteCM.length) {
        updates.deleteCM.forEach((element) => {
          const bodyContent = JSON.stringify({
            type: element.type,
            value: element.value,
          });

          const reqOptions = {
            url: `${process.env.REACT_APP_DOMAIN}/api/v1/contactMethods/${element?.id}`,
            method: "DELETE",
            headers: headersList,
            data: bodyContent,
          };

          axios
            .request(reqOptions)
            .then((responseContactMethods) => {})
            .catch((error) => {
              console.log(error);
              throw error;
            });
        });
      }

      // Create Contact Methods
      if (id && updates?.postCM.length) {
        updates.postCM.forEach((element) => {
          const bodyContent = JSON.stringify({
            type: element.type,
            value: element.value,
          });

          const reqOptions = {
            url: `${process.env.REACT_APP_DOMAIN}/api/v1/contacts/${id}/contactMethods`,
            method: "POST",
            headers: headersList,
            data: bodyContent,
          };

          axios
            .request(reqOptions)
            .then((responseContactMethods) => {})
            .catch((error) => {
              console.log(error);
              throw error;
            });
        });
      }

      // Update Contact Methods
      if (updates?.pathCM.length) {
        updates?.pathCM.forEach((element) => {
          const bodyContent = JSON.stringify({
            type: element.type,
            value: element.value,
          });

          const reqOptions = {
            url: `${process.env.REACT_APP_DOMAIN}/api/v1/contactMethods/${element?.id}`,
            method: "PATCH",
            headers: headersList,
            data: bodyContent,
          };

          axios
            .request(reqOptions)
            .then((responseContactMethods) => {})
            .catch((error) => {
              console.log(error);
              throw error;
            });
        });
      }

      // Tags

      // Delete Tags
      if (updates?.deleteTag.length) {
        const bodyContent = JSON.stringify(updates.deleteTag);

        const reqOptions = {
          url: `${process.env.REACT_APP_DOMAIN}/api/v1/contacts/${id}/tags`,
          method: "DELETE",
          headers: headersList,
          data: bodyContent,
        };
        axios
          .request(reqOptions)
          .then((responseTags) => {})
          .catch((error) => {
            console.log(error);
            throw error;
          });
      }

      // Create Tags
      if (updates?.postTag.length) {
        const bodyContent = JSON.stringify(updates.postTag);

        const reqOptions = {
          url: `${process.env.REACT_APP_DOMAIN}/api/v1/contacts/${id}/tags`,
          method: "POST",
          headers: headersList,
          data: bodyContent,
        };
        axios
          .request(reqOptions)
          .then((responseTags) => {})
          .catch((error) => {
            console.log(error);
            throw error;
          });
      }

      // Memberships

      // Create memberships
      const existsMembership = tableResponse?.data?.memberships?.find(
        (el: any) => el.id === membershipFunctions?.selectedMembership?.id
      );

      if (
        !existsMembership &&
        membershipFunctions?.selectedContactLists?.id?.length
      ) {
        const bodyContent = JSON.stringify({
          contactId: id,
        });

        const reqOptions = {
          url: `${process.env.REACT_APP_DOMAIN}/api/v1/contactLists/${membershipFunctions?.selectedContactLists?.id}/memberships`,
          method: "POST",
          headers: headersList,
          data: bodyContent,
        };

        axios
          .request(reqOptions)
          .then((responseMembership) => {
            if (membershipFunctions?.consentDate?.length) {
              const bodyContent = JSON.stringify({
                consentDate: membershipFunctions?.consentDate,
                gdprOptIn: membershipFunctions?.selectedGdpr?.id,
              });

              const reqOptions = {
                url: `${process.env.REACT_APP_DOMAIN}/api/v1/memberships/${responseMembership?.data?.id}`,
                method: "PATCH",
                headers: headersList,
                data: bodyContent,
              };

              axios
                .request(reqOptions)
                .then((responseConsent) => {})
                .catch((error) => {
                  console.error(error);
                  throw error;
                });
            }
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      } else {
        // Update memberships

        const oldConsentDate = existsMembership?.consentDate;

        const oldgdprOptIn = existsMembership?.gdprOptIn;

        const consentDate = membershipFunctions?.consentDate ?? oldConsentDate;

        const selectedGdpr = membershipFunctions?.selectedGdpr?.id;

        const constants = [
          oldConsentDate,
          oldgdprOptIn,
          consentDate,
          selectedGdpr,
        ];

        if (constants.some((constant) => constant ?? false)) {
          if (consentDate !== oldConsentDate || selectedGdpr !== oldgdprOptIn) {
            const bodyContent = JSON.stringify({
              consentDate: membershipFunctions?.consentDate,
              gdprOptIn: membershipFunctions?.selectedGdpr?.id ?? null,
            });

            const reqOptions = {
              url: `${process.env.REACT_APP_DOMAIN}/api/v1/memberships/${membershipFunctions?.selectedMembership?.id}`,
              method: "PATCH",
              headers: headersList,
              data: bodyContent,
            };

            axios
              .request(reqOptions)
              .then((responseMembership) => {})
              .catch((error) => {
                console.error(error);
                throw error;
              });
          }
        }
      }

      // attachments
      if (
        membershipFunctions?.attachments &&
        membershipFunctions?.attachments.length
      ) {
        membershipFunctions?.attachments.forEach((el) => {
          const formData = new FormData();
          formData.append("attachment", el);

          const bodyContent = formData;

          const reqOptions = {
            url: `${process.env.REACT_APP_DOMAIN}/api/v1/memberships/${membershipFunctions?.selectedMembership?.id}/attachments`,
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            data: bodyContent,
          };

          axios
            .request(reqOptions)
            .then((responseMembership) => {})
            .catch((error) => {
              console.error(error);
              throw error;
            });
        });
      }

      // Create memberships by ContactLists
      if (membershipUpdates?.membershipsToAdd?.length) {
        membershipUpdates?.membershipsToAdd?.forEach((elId) => {
          const bodyContent = JSON.stringify({
            contactId: elId,
          });

          const reqOptions = {
            url: `${process.env.REACT_APP_DOMAIN}/api/v1/contactLists/${id}/memberships`,
            method: "POST",
            headers: headersList,
            data: bodyContent,
          };

          axios
            .request(reqOptions)
            .then((responseMembership) => {})
            .catch((error) => {
              console.error(error);
              throw error;
            });
        });
      }

      // Delete memberships by ContactLists
      if (membershipUpdates?.membersToDelete?.length) {
        membershipUpdates?.membersToDelete?.forEach((elId) => {
          const reqOptions = {
            url: `${process.env.REACT_APP_DOMAIN}/api/v1/memberships/${elId}`,
            method: "DELETE",
            headers: headersList,
            data: bodyContent,
          };

          axios
            .request(reqOptions)
            .then((responseMembership) => {})
            .catch((error) => {
              console.error(error);
              throw error;
            });
        });
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });

  return responseData;
}

export default UpdateFunction;
